import { useEffect, useState } from "react";
import { selectedCourses } from "../../../../types";
import { CourseCard } from "../CourseCard/CourseCard";

type Props = {
    courses: selectedCourses[];
    selectCourses: (selections: selectedCourses[]) => void;
    removePacks: () => void;
}

export function YearCourses({courses, selectCourses, removePacks}: Props) {
    const [courseList, setCourseList] = useState<selectedCourses[]>([])
    const [cssClasses, setCssClasses] = useState<string>('')

    useEffect(() => {
        const isPrevSem = JSON.parse(localStorage.getItem('prevSem') || 'false')
        let selected = courses.filter((c) => c.selectedSemester === 'yearspring' && c.earlyStart !== true || c.selectedSemester === 'years' && isPrevSem)

        setCourseList(selected)

    }, [courses])

    useEffect(() => {
        if(courseList.length > 0){
            setCssClasses('h-full flex flex-col gap-2 p-2')
        } else {
            setCssClasses('pt-2')
        }
    }, [courseList])

    function removeCourse(course: selectedCourses) {
        let courseArr = [...courses]
        /* if(course.packCourse === true){
            courseArr = courseArr.filter((c) => c.packCourse !== true)
            removePacks();
        } */
        const index = courseArr.findIndex((c) => c.courseCode === course.courseCode)
        if(index !== -1) {
            courseArr.splice(index, 1)
        }
        selectCourses(courseArr)
    }

    function changeCourses(courses: selectedCourses[]) {
        selectCourses(courses)
    }

    return(
        <div className={cssClasses}>
            {courseList.map((c, i) => (
                <CourseCard key={c.courseCode + '-year-courses-' + i} selectedCourses={courses} course={c} semester={'year'} removeCourse={(course) => removeCourse(course)} changeCourses={(courses) => changeCourses(courses)}/>
            ))}
        </div>
    )
}