import { classes } from '../utils/helpers';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export function Container({ children, className = '' }: Props) {
  return (
    <div className={classes('px-2 tablet-s:px-8 laptop-s:px-0 laptop-s:mx-auto laptop-s:w-[900px] laptop-m:w-[1000px]  ', className)}>
      {children}
    </div>
  );
}