import { useLocation, Link, useNavigate } from 'react-router-dom';
import './NavigationPearls.css'
import { Fragment, useEffect, useState } from 'react';

interface Props {
    links: { title: string, url: string }[];
}

export function NavigationPearls({ links }: Props) {
    const [locationString, setLocationString] = useState<string>('')
    const [locationIndex, setLocationIndex] = useState<number>(0)
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        let locString = location.pathname;
        if (locString === '/utelukk-tidspunkter') {
            locString = '/timeplan'
        }
        if (locString === '/bestill') {
            locString = '/paamelding'
        }
        if (locString === '/bekreftet') {
            locString = '/paamelding'
        }
        setLocationString(locString)
        links.map((link, index) => {
            if (link.url === locString) {
                setLocationIndex(index)
            }
        })
    }, [location])

    useEffect(() => {
        checkIfMissingParams(locationString)
    }, [locationString])

    // Redirect to the correct page if any of the required data is missing
    function checkIfMissingParams(url: string){
        const schoolName = localStorage.getItem('schoolName') || ''
        const schoolType = localStorage.getItem('schoolType') || ''
        const schoolAlias = localStorage.getItem('schoolAlias') || ''
        const selections = JSON.parse(localStorage.getItem('selectedCourses') || '[]')
        if(url === '/fag'){
            if(schoolAlias === '' || schoolName === '' || schoolType === '') {
                navigate('/')
            }
        } else if(url === '/paamelding'){
            if(schoolAlias !== '' && schoolName !== '' && schoolType !== '' && selections.length <= 0) {
                navigate('/fag')
            } else if(schoolAlias === '' || schoolName === '' || schoolType === '') {
                navigate('/')
            }
        }
    }

    function changePage(url: string) {
        if (url === '/fag') {
            const schoolName = localStorage.getItem('schoolName') || ''
            const schoolType = localStorage.getItem('schoolType') || ''
            const schoolAlias = localStorage.getItem('schoolAlias') || ''
            if (schoolAlias !== '' && schoolName !== '' && schoolType !== '') {
                navigate(url)
            }
        } else if (url === '/paamelding') {
            const courseList = JSON.parse(localStorage.getItem('selectedCourses') || '[]')
            if (courseList.length > 0) {
                navigate(url)
            }
        } else {
            navigate(url)
        }
    }

    return (
        <div className='flex justify-center'>
            <div className="flex w-full tablet-s:w-2/4 mb-2 mt-0 laptop-s:mt-4 laptop-s:mb-4">
                {links.map((link, index) => (
                    <Fragment key={link.url}>
                        <div key={`link-${link.title}-pearl`} onClick={() => changePage(link.url)} className="pearlLink hover:cursor-pointer w-full flex flex-col items-center justify-center">
                            <span className={`pearl ${(link.url === locationString) ? 'active' : ''} ${index < locationIndex ? 'finished' : ''}`}>{index + 1}</span>
                            <span className='whitespace-nowrap w-fit'>{link.title}</span>
                        </div>
                        {links.length !== index + 1 && <div key={`link-${link.title}-line`} className={index < locationIndex ? 'pearlChain finished' : 'pearlChain'}></div>}
                    </Fragment>
                ))}
            </div>
        </div>
    )
}