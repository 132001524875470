const GetExamArray: { [key: string]: { type: string; date: string }[] } = {
    "NOR1267/NOR1268/NOR1269": [
        {"type": "written-exam", "date": "16. mai"},
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"}
    ],
    "SAM3073": [{"type": "written-exam", "date": "13.mai"}],
    "REL1003": [{"type": "oral-exam", "date": "Du får dato av privatistkontoret"}],
    "NAT1018": [{"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}],
    "NAT1007": [{"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}],
    "REA3062": [{"type": "written-exam", "date": "14. mai"}],
    "REA3058": [{"type": "written-exam", "date": "14. mai"}],
    "MAT1151": [{"type": "written-exam", "date": "19. mai"}],
    "KRO1019": [{"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}],
    "REA3046/REA3047": [
        {"type": "written-exam", "date": "19. mai"},
        {"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}
    ],
    "HIS1010": [{"type": "oral-exam", "date": "Du får dato av privatistkontoret"}],
    "HIS1011": [{"type": "oral-exam", "date": "Du får dato av privatistkontoret"}],
    "REA3043/REA3044": [
        {"type": "written-exam", "date": "23. mai"},
        {"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}
    ],
    "REA3039/REA3040": [
        {"type": "written-exam", "date": "21. mai."},
        {"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}
    ],
    "REA3036/REA3037": [
        {"type": "written-exam", "date": "13. mai"},
        {"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}
    ],
    "SAM3055": [{"type": "written-exam", "date": "19. mai"}],
    "SAM3058": [{"type": "written-exam", "date": "22. mai"}],
    "REA3049/REA3050": [
        {"type": "written-exam", "date": "12. mai"},
        {"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}
    ],
    "SPR3031/SPR3032": [
        {"type": "written-exam", "date": "21. mai"},
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"}
    ],
    "MAT1019": [{"type": "written-exam", "date": "21. mai"}],
    "SAM3051": [{"type": "written-exam", "date": "12. mai"}],
    "SAM3052": [{"type": "oral-exam", "date": "Du får dato av privatistkontoret"}],
    "SAM3053": [{"type": "oral-exam", "date": "Du får dato av privatistkontoret"}],
    "MAT1019/MAT1023": [
        {"type": "written-exam", "date": "21. mai"},
        {"type": "written-exam", "date": "19. mai"}
    ],
    "ulike y-koder/MAT1151": [
        {"type": "written-exam", "date": "12. mai"},
        {"type": "written-exam", "date": "19. mai"}
    ],
    "REA3048": [{"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}],
    "SPR3029/SPR3030": [
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"},
        {"type": "written-exam", "date": "22. mai"}
    ],
    "ulike y-koder": [{"type": "written-exam", "date": "12. mai"}],
    "SAK1001": [{"type": "oral-exam", "date": "Du får dato av privatistkontoret"}],
    "FSP6222/FSP6223": [
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"},
        {"type": "written-exam", "date": "13. mai"}
    ],
    "SAM3054": [{"type": "oral-exam", "date": "Du får dato av privatistkontoret"}],
    "MAT1021": [{"type": "written-exam", "date": "21. mai"}],
    "REA3060": [{"type": "written-exam", "date": "19. mai"}],
    "REA3045": [{"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}],
    "REA3042": [{"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}],
    "REA3056": [{"type": "written-exam", "date": "19. mai"}],
    "REA3038": [{"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}],
    "REA3035": [{"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}],
    "MAT1023": [{"type": "written-exam", "date": "19. mai"}],
    "SAM3072": [{"type": "oral-exam", "date": "Du får dato av privatistkontoret"}],
    "SAM3057": [{"type": "oral-exam", "date": "Du får dato av privatistkontoret"}],
    "PSP5828/PSP5829": [
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"},
        {"type": "written-exam", "date": "13. mai"}
    ],
    "PSP5826/PSP5827": [
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"},
        {"type": "written-exam", "date": "14. mai"}
    ],
    "PSP5838/PSP5839": [
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"},
        {"type": "written-exam", "date": "14. mai"}
    ],
    "FSP6239/FSP6240": [
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"},
        {"type": "written-exam", "date": "14. mai"}
    ],
    "GEO1003": [{"type": "oral-exam", "date": "Du får dato av privatistkontoret"}],
    "ENG1007/ENG1008": [
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"},
        {"type": "written-exam", "date": "20. mai"}
    ],
    "NOR1269": [{"type": "oral-exam", "date": "Du får dato av privatistkontoret"}],
    "NOR1267/NOR1268": [
        {"type": "written-exam", "date": "16. mai"},
        {"type": "written-exam", "date": "15. mai"}
    ],
    "NOR1268": [{"type": "written-exam", "date": "15. mai"}],
    "REA3056/REA3058": [
        {"type": "written-exam", "date": "19. mai"},
        {"type": "written-exam", "date": "14. mai"}
    ],
    "PSP5840/PSP5841": [
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"},
        {"type": "written-exam", "date": "13. mai"}
    ],
    "FSP6242/FSP6243": [
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"},
        {"type": "written-exam", "date": "13. mai"}
    ],
    "FSP6219/FSP6220": [
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"},
        {"type": "written-exam", "date": "14. mai"}
    ],
    "pack-MAT-online-MAT1019/MAT1023": [
        {"type": "written-exam", "date": "21. mai"},
        {"type": "written-exam", "date": "19. mai"}
    ],
    "pack-MAT-online-ulike y-koder/MAT1151": [
        {"type": "written-exam", "date": "12. mai"},
        {"type": "written-exam", "date": "19. mai"}
    ],
    "FSP6226/FSP6227": [
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"},
        {"type": "written-exam", "date": "13. mai"}
    ],
    "PSP5830/PSP5831": [
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"},
        {"type": "written-exam", "date": "12. mai"}
    ],
    "PSP5842/PSP5843": [
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"},
        {"type": "written-exam", "date": "12. mai"}
    ],
    "NOR1420/NOR1421": [
        {"type": "oral-exam", "date": "Du får dato av privatistkontoret"},
        {"type": "written-exam", "date": "16. mai"}
    ],
    "HSF1006": [{"type": "written-exam", "date": "12. mai"}],
    "HSF1007": [{"type": "written-exam", "date": "13. mai"}],
    "HSF1008": [{"type": "written-exam", "date": "15. mai"}],
    "HSF1009": [{"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}],
    "HES2005": [{"type": "written-exam", "date": "19. mai"}],
    "HES2006": [{"type": "written-exam", "date": "22. mai"}],
    "HES2007": [{"type": "written-exam", "date": "21. mai"}],
    "HES2008": [{"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}],
    "HSE3005": [{"type": "written-exam", "date": "12. mai"}],
    "HSE3006": [{"type": "written-exam", "date": "13. mai"}],
    "HSE3007": [{"type": "written-exam", "date": "15. mai"}],
    "HSE3008": [{"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}],
    "HEA2005": [{"type": "written-exam", "date": "19. mai"}],
    "HEA2006": [{"type": "written-exam", "date": "21. mai"}],
    "HEA2007": [{"type": "written-exam", "date": "22. mai"}],
    "HEA2008": [{"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}],
    "BUA2005": [{"type": "written-exam", "date": "19. mai"}],
    "BUA2006": [{"type": "written-exam", "date": "22. mai"}],
    "BUA2007": [{"type": "written-exam", "date": "21. mai"}],
    "BUA2008": [{"type": "oral-lab-exam", "date": "Du får dato av privatistkontoret"}],
    "HEA3103": [{"type": "written-exam", "date": "19. mai"}],
    "BUA3103": [{"type": "written-exam", "date": "19.mai"}]
}

export default GetExamArray;
