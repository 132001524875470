import { Button } from './Button/Button';
import { Container } from './Container';
import { Logo } from './Logo';
import HelpIcon from '../../../assets/help.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowButton } from './ArrowButton/ArrowButton';
import { useEffect, useState } from 'react';
import Modal from './Modal/Modal';
import { Icon } from './Icon/Icon';

export function Header() {
  const [showBack, setShowBack] = useState<boolean>(true)
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const navigate = useNavigate();
  const location = useLocation();

  function handleCloseModal() {
    setModalOpen(false)
  }

  function handleOpenModal() {
    setModalOpen(true)
  }

  useEffect(() => {
    if (location.pathname === '/') {
      setShowBack(false)
    } else {
      setShowBack(true)
    }
  }, [location])

  return (
    <header className='bg-sonans-primary w-full sticky top-0 z-50'>
      <Container className='py-4 laptop-m:py-8'>
        <div className='flex justify-between w-full'>
          <div className='w-full flex justify-start'>
            {showBack && <ArrowButton arrowStyle='w-6' onClick={() => navigate(-1)} mode='nav-left' />}
          </div>
          <div className='w-full flex justify-center items-center'>
            <a href='https://sonans.no/'>
              <Logo type='logo' className='w-24' />
            </a>
          </div>
          <div className='w-full flex justify-end'>
            <Button onClick={() => handleOpenModal()} className="flex items-center gap-2" mode='primary'><Icon className='w-6' type='help'/>Hjelp</Button>
            <Modal id='help_popup' title='Er du usikker på hva som er den beste løsningen for deg?' isOpen={modalOpen} onClose={() => handleCloseModal()}>
              <div className="flex flex-col gap-4 items-center">
                <p className="tablet-s:text-lg">Fortsett sammen med en rådgiver</p>
                <a id="call_help_button" className="tablet-s:text-xl font-bold rounded-md border border-black py-2 px-4 bg-sonans-primary hover:bg-black hover:text-white" href="tel:+4791504070">Ring 915 040 70</a>
              </div>
            </Modal>
          </div>



        </div>
      </Container>

    </header>
  );
}