import { useEffect, useState } from "react";
import { selectedCourses } from "../../../../types"
import { getChosenSemVariants } from "../../../Helpers/CourseSearch/CourseSearchHelpers";
import { ExamArray } from "../../../Helpers/GetText/GetText";
import { Icon } from "../Icon/Icon";
import Modal from "../Modal/Modal"

type Props = {
    courses: selectedCourses[];
    semester: string;
    showInfoIcon: boolean;
}

export function StudyLoad({ courses, semester, showInfoIcon }: Props) {
    const [exams, setExams] = useState<[]>([])
    const [load, setLoad] = useState<number>(0)
    const [loadPercentage, setLoadPercentage] = useState<number>(0)
    const [showWarning, setShowWarning] = useState<boolean>(false)
    const [fullTime, setFullTime] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    function handleCloseModal() {
        setModalOpen(false)
    }

    function handleOpenModal() {
        setModalOpen(true)
    }

    useEffect(() => {
        const shownSemesters = getChosenSemVariants(semester);
        const selected = courses.filter((c) => shownSemesters.indexOf(c.selectedSemester) !== -1)
        const yearCourses = courses.filter((c) => c.selectedSemester === 'year' && c.earlyStart !== true);
        const yearSpringCourses = courses.filter((c) => c.selectedSemester === 'yearspring');
        const earlyStartCourses = courses.filter((c) => c.selectedSemester === 'year' && c.earlyStart === true)
        let exams: any = []
        let hours = 0
        selected.map((c) => {
            const courseExams = ExamArray(c.courseCode);
            hours = hours + c.selectedCourse.yearHours
            courseExams.map((e) => {
                exams.push(e.type)
            })
        })
        yearSpringCourses.map((y) => {
            const halfHours = y.selectedCourse.yearHours / 2;
            if (semester === 'spring') {
                hours += halfHours;
            }

            if (semester === 'autumn') {
                hours += halfHours
                const courseExams = ExamArray(y.courseCode);
                courseExams.map((e) => {
                    exams.push(e.type)
                })
            }
        })
        yearCourses.forEach((y) => {
            const halfHours = y.selectedCourse.yearHours / 2;
            
            if (semester === 'autumn') {
                hours += halfHours;
                const courseExams = ExamArray(y.courseCode);
                courseExams.forEach((e) => {
                    exams.push(e.type)
                })
            }
        })
        earlyStartCourses.forEach((e) => {
            if (semester === 'spring') {
                const courseExams = ExamArray(e.courseCode);
                hours = hours + e.selectedCourse.yearHours
                courseExams.forEach((e) => {
                    exams.push(e.type)
                })
            }
        })

        const percent = Math.trunc((hours / 420) * 100)
        if (percent >= 160) {
            setShowWarning(true)
        } if (percent < 160) {
            setShowWarning(false)
        }

        if (percent >= 100) {
            setFullTime(true)
        } else {
            setFullTime(false)
        }


        setExams(exams)
        setLoad(hours)
        setLoadPercentage(percent)

    }, [courses, semester])


    return (
        <div className="w-full">
            <div onClick={() => handleOpenModal()} className="flex justify-between hover:cursor-pointer">
                <div className="flex flex-col w-full justify-around gap-0 divide-y-2 text-sm tablet-s:text-base divide-black p-2 tablet-s:flex-row tablet-s:divide-y-0 tablet-s:divide-x-2 tablet-s:gap-4">
                    <p className="font-semibold w-full">{exams.length} eksamen{(exams.length > 1 || exams.length === 0) ? 'er' : ''}</p>
                    <p className="font-semibold w-full flex justify-center items-center gap-2">{loadPercentage}% {fullTime ? 'fulltid' : 'deltid'} {showWarning && <Icon className="w-6" type='warning-outline' />}</p>
                </div>
                {showInfoIcon && <div className="p-2">
                    <Icon className="w-4" type="info" />
                </div>}
            </div>
            <Modal id="studyload_popup" title='Studiebelastning:' isOpen={modalOpen} onClose={() => handleCloseModal()}>
                <div className="text-sm tablet-s:text-base">
                    <p>Studiebelastning har betydning for lånekassestøtte og arbeidsmengde.</p>
                    <ul>
                        <li>Over 100% = fulltidsstudent</li>
                        <li>Under 100% = deltidsstudent</li>
                    </ul>
                    <p>100% studiebelastning kan sammenlignes med en 100% stilling (37,5 timer/uke)</p>
                </div>
            </Modal>
        </div>
    )
}