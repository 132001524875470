import { classes } from '../../utils/helpers';
import './button.css';

type ButtonMode = 'primary' | 'dark' | 'light' | 'yellow' | 'green' | 'pink';

type Props = {
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  mode?: ButtonMode;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  type?: 'submit' | 'button';
};

export function Button({
  onClick,
  className = '',
  children,
  disabled = false,
  mode = 'primary',
  type = 'button',
}: Props) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={classes(
        `text-sm tablet-m:text-md tablet-s:text-xl font-bold rounded-lg shadow-md border border-black py-2 tablet-s:px-4 disabled:opacity-50 disabled:bg-bg-grey disabled:text-text-grey hover:scale-[1.02] transition-all mobile-s:px-2 mobile-s:text-sm`,
        mode === 'primary' ? 'bg-sonans-primary hover:bg-black hover:text-white button-icon-dark' : '',
        mode === 'dark' ? 'bg-black text-white hover:bg-sonans-primary hover:text-black button-icon-light' : '',
        mode === 'light' ? 'bg-white text-black hover:bg-black hover:text-white button-icon-dark' : '',
        mode === 'yellow' ? 'bg-first-color text-black hover:bg-black hover:text-white button-icon-dark' : '',
        mode === 'green' ? 'bg-third-color text-black hover:bg-black hover:text-white button-icon-dark' : '',
        mode === 'pink' ? 'bg-second-color text-black hover:bg-black hover:text-white button-icon-dark' : '',
        className
      )}>
      {children}
    </button>
  );
}
