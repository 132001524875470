import { useEffect, useState } from 'react';
import {
  CourseVariants,
  ICourse,
  ICourses,
  IconTypes,
  PackStructure,
  StructuredCourse,
  fullPacks,
  selectedCourses,
  PackStructureCourse,
} from '../../../../types';
import { CourseFromId } from '../../../Helpers/Translators/CourseFromId';
import { PackInformation } from '../../../Helpers/GetText/GetText';
import { PackExtra } from '../../../Helpers/GetText/GetText';
import { ExamArray } from '../../../Helpers/GetText/GetText';
import { Icon } from '../Icon/Icon';
import { Button } from '../Button/Button';
import { getCourseType } from '../../../Helpers/CourseSearch/CourseSearchHelpers';
import FormatPrice from '../../../Helpers/Functions/FormatPrice';
import { getNonPackPrice } from '../../../Helpers/Functions/GetNonPackPrice';
import dataLayer from '../../../../utils/dataLayer';
import { findOfferedPackageCourses } from '../../../Helpers/PackageCoursePicker/PackageCoursePicker';
import { getSemesterPackCourses } from '../../../Helpers/GetText/libraries/GetPacks';

interface Props {
  thisPack: PackStructure;
  allPacks: fullPacks[];
  courses: ICourses[];
  vocationalPack: boolean;
  allStructuredCourses: StructuredCourse[];
  selCourses: selectedCourses[];
  updateCourses: (selections: selectedCourses[], pack: PackStructure) => void;
  updateShownSType: (SType: string) => void;
  closeModal: () => void;
}

export function PackPopup({
  thisPack,
  allPacks,
  courses,
  selCourses,
  updateCourses,
  closeModal,
  allStructuredCourses,
  updateShownSType,
  vocationalPack,
}: Props) {
  const [pack, setPack] = useState<PackStructure>(thisPack);
  const [schoolType, setSchoolType] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('');
  const [schoolAlias, setSchoolAlias] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [schoolName, setSchoolName] = useState<string>('');
  const [packCourses, setPackCourses] = useState<ICourse[]>([]);
  const [packInfo, setPackInfo] = useState<string>('');
  const [packExtra, setPackExtra] = useState<string[]>([]);
  const [showExtra, setShowExtra] = useState<boolean>(false);
  const [campusPack, setCampusPack] = useState<PackStructure>();
  const [livePack, setLivePack] = useState<PackStructure>();
  const [onlinePack, setOnlinePack] = useState<PackStructure>();
  const [pCoursesStructured, setPCoursesStructured] = useState<selectedCourses[]>();
  const [isPrevSem, setIsPrevSem] = useState<boolean>(false);
  const [packPlace, setPackPlace] = useState<string>('');

  useEffect(() => {
    const packInfo = PackInformation(pack.title.replace(' online', '').replace(' live', ''));
    const packExtra = packInfo.slice(1);
    setPackInfo(packInfo[0]);
    setPackExtra(packExtra);
  }, [pack, courses]);

  useEffect(() => {
    if (pack.courses && schoolAlias) {
      getPackCourses(pack, schoolAlias, selectedType);
    }
  }, [pack, schoolAlias, selectedType]);

  // Get schooltype packs
  useEffect(() => {

    let onlinePacks;
    onlinePacks = allPacks.filter((ap) => ap.type === 'online');

    if (vocationalPack) {
      onlinePacks = allPacks.filter((ap) => ap.type === 'live');
    }
    const onlinePack = onlinePacks[0].packs.filter((op) => op.title.includes(pack.title));

    const livePacks = allPacks.filter((ap) => ap.type === 'live');
    const livePack = livePacks[0].packs.filter((lp) => lp.title.includes(pack.title));

    const campusPacks = allPacks.filter((ap) => ap.type === 'campus');
    const campusPack = campusPacks[0].packs.filter((cp) => cp.title.includes(pack.title));

    const schoolType = localStorage.getItem('schoolType') || '';
    const schoolTitle = localStorage.getItem('schoolName') || '';
    setSchoolName(schoolTitle);
    setSchoolType(schoolType);
    setSelectedType(schoolType);
    let location = localStorage.getItem('schoolAlias') || '';
    if (schoolType === 'kombi') {
      location = 'sonans';
      setPackPlace('');
    } else if (schoolType !== 'kombi') {
      setPackPlace(pack.placement);
    }
    setLocation(location);
    setSchoolAlias(location);

    gtagViewPack();

    setOnlinePack(onlinePack[0]);
    setLivePack(livePack[0]);
    setCampusPack(campusPack[0]);
    const isPrevSem = JSON.parse(localStorage.getItem('prevSem') || 'false');
    setIsPrevSem(isPrevSem);
  }, []);

  function gtagViewPack() {
    if (pack.alias !== 'sonans_pack') {
      const event = {
        event: 'view_item',
        currency: 'NOK',
        value: pack.price,
        items: [
          {
            item_name: pack.title,
            discount: 0,
            item_type: '',
            item_brand: 'Sonans',
            item_category: 'pakke',
            item_id: pack.lookup.text,
            price: pack.price,
            quantity: 1,
          },
        ],
      };
      dataLayer.push(event);
    }
  }

  function structureCourse(packCourse: ICourse) {
    const course: StructuredCourse = {
      courseCode: packCourse.course_code,
      courseTitle: packCourse.title.replace(' live', '').replace(' online', ''),
      labCourses: [],
      places: packCourse.places,
      times: [packCourse.time],
      upgradeCourses: [],
      upgradePlaces: [],
      variants: [],
      vocationalCategory: '',
      vocationalSubject: false,
    };

    return course;
  }

  function getPackCourses(packObj: PackStructure, alias: string, type: string) {
    let pCourses: ICourse[] = [];

    let tmpLocal: IconTypes = 'online';

    if (vocationalPack) {
        tmpLocal = 'live';
    }

    if (type == 'live') {
      tmpLocal = 'live';
    }
    if (type == 'campus') {
      tmpLocal = 'campus';
    }

    const tmp_courses = findOfferedPackageCourses(pack.courses, tmpLocal, allStructuredCourses, alias, pack.title);

    let tempC: ICourse[] = [];

    tmp_courses.map((c) => {
      const co = CourseFromId(c.selectedCourse.courseId, c.selectedTime, courses);
      if (co && co !== 'not found') {
        tempC.push(co);
      }
    });

    packObj.courses.map((course) => {
      const c = CourseFromId(course.course_id, course.semester_length_alias, courses);
      if (c && c !== 'not found') {
        pCourses.push(c);
      }
    });

    tempC = tempC.sort((a, b) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
      if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
      return 0;
    });

    setPCoursesStructured(tmp_courses);
    setPackCourses(tempC);
  }

  function changePackLocation(packObj: PackStructure, location: string) {
    setLocation(location);
    setPackPlace(location);
    setPack(packObj);
    let alias = 'sonans';
    if (location === 'live') {
      alias = 'sondig';
    } else if (location === 'online') {
      alias = 'sonans';
    } else if (location !== 'sondig' && location !== 'sonans') {
      alias = localStorage.getItem('schoolAlias') || '';
    }
    setSchoolAlias(alias);
    setSelectedType(location);
  }

  function structureSelection(packCourse: ICourse) {
    const selection: CourseVariants = {
      courseCode: packCourse.course_code,
      courseId: packCourse.course_id,
      courseTitle: packCourse.title.replace(' live', '').replace(' online', ''),
      places: packCourse.places,
      price: packCourse.price,
      priceNetFullyear: packCourse.online_year_price,
      priceNetHalfyear: packCourse.online_half_year_price,
      time: packCourse.time,
      yearHours: packCourse.year_hours,
    };

    return selection;
  }

  function addPack(replace: boolean) {
    const type = pack.placement;
    const courseArrNoPacks = selCourses.filter((sc) => sc.packCourse !== true);
    let courseArr = [...courseArrNoPacks];
    if (replace) {
      courseArr = [];
    }

    packCourses.map((pc) => {
      const course = structureCourse(pc);
      const selection = structureSelection(pc);

      let price = pc.price;
      let semester = pc.time;

      if (type === 'online' && pack.courses) {
        const onlineCourse = pack.courses.filter((p) => p.course_id === pc.course_id);
        if (onlineCourse.length > 0) {
          semester = onlineCourse[0].alternative_type;

          if (semester === 'year' || semester === 'yearspring') {
            price = pc.online_year_price;
          } else {
            price = pc.online_half_year_price;
          }
        }
      }

      if (pc.placement === 'online' && pCoursesStructured) {
        const course = pCoursesStructured.filter((c) => c.courseCode === pc.course_code);
        semester = course[0].selectedSemester;
        if (semester === 'year' || semester === 'yearspring') {
          price = pc.online_year_price;
        } else {
          price = pc.online_half_year_price;
        }
      }

      const pCourseStruct = pCoursesStructured?.filter((c) => c.courseCode === pc.course_code);

      const selectedCourse: selectedCourses = {
        courseCode: course.courseCode,
        type: pc.placement as IconTypes,
        course: course,
        selectedSemester: semester,
        selectedCourse: selection,
        selectedPrice: price,
        selectedTime: pc.time,
        variants: pCourseStruct ? pCourseStruct[0].variants : [],
        labCourses: pCourseStruct ? pCourseStruct[0].labCourses : [],
        upgradeCourses: pCourseStruct ? pCourseStruct[0].upgradeCourses : [],
        upgradeTimes: pCourseStruct ? pCourseStruct[0].upgradeTimes : [],
        packCourse: true,
        pack: pack.alias,
        packTitle: pack.title,
      };

      const index = courseArr.findIndex((ca) => ca.courseCode === pc.course_code);


      if (index !== -1) {
        courseArr[index] = selectedCourse;
      } else {
        courseArr.push(selectedCourse);
      }
    });


    const addPackEvent = {
      event: 'add_to_cart',
      currency: 'NOK',
      value: pack.price,
      items: [
        {
          item_name: pack.title,
          discount: 0,
          item_type: '',
          item_brand: 'Sonans',
          item_category: 'pakke',
          item_id: pack.lookup.text,
          price: pack.price,
          quantity: 1,
        },
      ],
    };

    dataLayer.push(addPackEvent);


    updateCourses(courseArr, pack);
    if (schoolType === 'kombi') {
      updateShownSType(pack.placement);
    }
    closeModal();
  }

  function getPrices(pack: PackStructure, schoolType: string) {
    const packPrice = pack.price;
    const noPackPrice = getNonPackPrice(pack, courses, schoolType, allStructuredCourses);

    if (noPackPrice <= packPrice) {
      return (
        <div>
          <p className='line-through'></p>
          <p className='text-lg font-semibold'>Pris {FormatPrice(noPackPrice)},-</p>
        </div>
      );
    } else {
      return (
        <div>
          <p className='line-through'>{FormatPrice(noPackPrice)},-</p>
          <p className='text-lg font-semibold'>Pris {FormatPrice(packPrice)},-</p>
        </div>
      );
    }
  }

  function getPricesKombi(pack: PackStructure, schoolType: string) {
    const packPrice = pack.price;
    const noPackPrice = getNonPackPrice(pack, courses, schoolType, allStructuredCourses);

    if (noPackPrice < packPrice) {
      return (
        <div>
          <p className='text-sm line-through'>
            <br />
          </p>
          <p>{FormatPrice(noPackPrice)},-</p>
        </div>
      );
    } else {
      return (
        <div>
          <p className='text-sm line-through'>{FormatPrice(noPackPrice)},-</p>
          <p>{FormatPrice(packPrice)},-</p>
        </div>
      );
    }
  }


  return (
    <div className='flex flex-col justify-center gap-4 p-2'>
      <p>{packInfo}</p>

      {packExtra.length > 0 && (
        <p onClick={() => setShowExtra(!showExtra)} className='underline font-semibold hover:cursor-pointer'>
          {showExtra ? 'Les mindre' : 'Les mer'}
        </p>
      )}
      {showExtra && (
        <ol className='list-disc text-left ml-8 mr-8'>
          {packExtra.map((extra, i) => (
            <li key={`${pack.alias}-pack-extra-${i}`}>{extra}</li>
          ))}
        </ol>
      )}
      <ul>
        {packCourses.map((course, i) => (
          <div key={course.course_code + '-pack-course-' + pack.alias + '-' + i} className='flex gap-2 justify-center'>
            <div className='flex gap-1'>
              {ExamArray(course.course_code).map((exam, y) => (
                <Icon
                  key={exam.type + '-pack-exam-' + course.course_code + '-' + i + '-' + y}
                  className='w-4'
                  type={exam.type as 'oral-lab-exam' | 'oral-exam' | 'written-exam'}
                />
              ))}
            </div>
            <li>{course.title.replace(' online', '').replace(' live', '')}</li>
          </div>
        ))}
      </ul>
      {schoolType !== 'kombi' && pack.alias !== 'sonans_pack' && <div>{getPrices(pack, vocationalPack ? "live" : schoolType)}</div>}
      {schoolType === 'kombi' && pack.alias !== 'sonans_pack' && <p className='font-semibold'>Velg sted:</p>}
      {schoolType === 'kombi' && pack.alias !== 'sonans_pack' && (
        <div className='flex flex-col tablet-s:flex-row gap-2 justify-center'>
          {campusPack && !isPrevSem && (
            <div onClick={() => changePackLocation(campusPack, 'campus')} className='flex gap-1'>
              <div
                className={`rounded-lg shadow-md border-4 ${
                  packPlace === campusPack.placement ? 'border-first-color-outline' : 'border-first-color'
                } bg-first-color p-2 flex`}>
                <Icon className='w-8 tablet-s:w-12' type='campus' />
              </div>
              <div
                className={`w-full flex flex-col border-4 rounded-md shadow-md text-left p-2 ${
                  packPlace === campusPack.placement && 'border-first-color'
                }`}>
                <p className='font-semibold'>{schoolName}</p>
                {getPricesKombi(campusPack, 'campus')}
              </div>
            </div>
          )}
          {livePack && (
            <div onClick={() => changePackLocation(livePack, 'live')} className='flex gap-1'>
              <div
                className={`rounded-lg shadow-md border-4 ${
                  packPlace === livePack.placement ? 'border-second-color-outline' : 'border-second-color'
                } bg-second-color p-2 flex`}>
                <Icon className='w-8 tablet-s:w-12' type='live' />
              </div>
              <div
                className={`w-full flex flex-col border-4 rounded-md shadow-md text-left p-2 ${
                  packPlace === livePack.placement && 'border-second-color'
                }`}>
                <p className='font-semibold'>Live</p>
                {getPricesKombi(livePack, 'live')}
              </div>
            </div>
          )}
          {onlinePack && !pack.vocationalPack && (
            <div onClick={() => changePackLocation(onlinePack, 'online')} className='flex gap-1'>
              <div
                className={`rounded-lg shadow-md border-4 ${
                  packPlace === onlinePack.placement ? 'border-third-color-outline' : 'border-third-color'
                } bg-third-color p-2 flex`}>
                <Icon className='w-8 tablet-s:w-12' type='online' />
              </div>
              <div
                className={`w-full flex flex-col border-4 rounded-md shadow-md text-left p-2 ${
                  packPlace === onlinePack.placement && 'border-third-color'
                }`}>
                <p className='font-semibold'>Online</p>
                {getPricesKombi(onlinePack, 'online')}
              </div>
            </div>
          )}
        </div>
      )}
      {isPrevSem && schoolType === 'kombi' && (
        <div className='border-4 rounded-lg border-third-color-outline bg-third-color flex flex-col mobile-s:text-sm justify-center p-2 mobile-s:p-1 mt-2 mb-2 tablet-s:mt-4 tablet-s:mb-4'>
          <p>Våren 2024: Meld deg på live- og onlinekurs her.</p>
          <p>
            For campus, ring{' '}
            <a id='call_help_button' className='underline font-semibold' href='tel:+4791504070'>
              915 040 70
            </a>
            .
          </p>
        </div>
      )}
      {pack.alias !== 'sonans_pack' && packPlace !== '' && (
        <div className='flex justify-center gap-4'>
          <Button onClick={() => addPack(false)} mode='primary'>
            Legg til pakken
          </Button>
          {selCourses.length > 0 && (
            <Button onClick={() => addPack(true)} mode='dark'>
              Erstatt valg med pakken
            </Button>
          )}
        </div>
      )}

      {pack.alias !== 'sonans_pack' && (
        <div className='flex flex-col gap-2 text-sm'>
          <p className='font-semibold'>Type eksamen:</p>
          <div className='flex justify-center gap-4'>
            <div className='flex gap-2 items-center'>
              <Icon className='w-4' type='written-exam' />
              <p>Skriftlig</p>
            </div>
            <div className='flex gap-2 items-center'>
              <Icon className='w-4' type='oral-exam' />
              <p>Muntlig</p>
            </div>
            <div className='flex gap-2 items-center'>
              <Icon className='w-4 flex-shrink-0' type='oral-lab-exam' />
              <p>Muntlig-praktisk</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
