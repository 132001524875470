import { useEffect, useState, useRef } from 'react';
import { ArrowButton } from '../ArrowButton/ArrowButton';
import Modal from '../Modal/Modal';

import './Carousel.css';
import {
  ICourses,
  PackStructure,
  selectedCourses,
  fullPacks,
  IPackageCoverage,
  StructuredCourse,
} from '../../../../types';
import { PackPopup } from './PackPopup';
import { getNonPackPrice } from '../../../Helpers/Functions/GetNonPackPrice';
import FormatPrice from '../../../Helpers/Functions/FormatPrice';
import { SortPacks } from '../../../Helpers/Functions/SortPacks';
import { CheckIfPack } from '../../../Helpers/Functions/CheckIfPack';
import { twJoin, twMerge } from 'tailwind-merge';

// custom hook to get previous state value
function usePrevious(value: number) {
  const ref = useRef<number>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

type Props = {
  coursePacks: PackStructure[];
  courses: ICourses[];
  selCourses: selectedCourses[];
  allStructuredCourses: StructuredCourse[];
  selPacks: PackStructure[];
  willGetNormalPack: IPackageCoverage | null;
  updatePackCourses: (selections: selectedCourses[]) => void;
  updatePack: (selections: PackStructure) => void;
};

export function Carousel({
  coursePacks,
  allStructuredCourses,
  courses,
  selCourses,
  selPacks,
  willGetNormalPack,
  updatePackCourses,
  updatePack,
}: Props) {
  const [coursePackArr, setCoursePackArr] = useState<PackStructure[]>([]);
  const [standardPackArr, setStandardPackArr] = useState<PackStructure[]>([]);
  const [fullPacksArr, setFullPacksArr] = useState<fullPacks[]>([]);
  const [modalStates, setModalStates] = useState<boolean[]>([]);
  const [sType, setSType] = useState<string>('');
  const [shownSType, setShownSType] = useState<string>('');
  const [selectedPack, setSelectedPack] = useState<PackStructure>();
  const [selFullCourses, setSelFullCourses] = useState<selectedCourses[]>([]);
  const [relevantPackTitle, setRelevantPackTitle] = useState<string>('');
  const [selectedPackTitle, setSelectedPackTitle] = useState<string>('');
  const [selectedPackPlace, setSelectedPackPLace] = useState<string>('');
  const previousLength = usePrevious(selFullCourses.length);
  const [filter, setFilter] = useState<'generalStudies' | 'vocationalStudies' | 'math'>('generalStudies');
  const [filteredPacks, setFilteredPacks] = useState<PackStructure[]>([]);
  const [previousFilter, setPreviousFilter] = useState<'generalStudies' | 'vocationalStudies' | 'math'>(
    'generalStudies'
  );

  const carouselDiv = useRef<HTMLDivElement>(null);

  function setFullPacks(packs: PackStructure[]) {
    let schoolTypesArr: string[] = ['campus', 'live', 'online'];
    let PacksArr: fullPacks[] = [];
    schoolTypesArr.map((type) => {
      const filterPacks = packs.filter((pack) => pack.placement.toLowerCase() === type.toLowerCase());

      const pack: fullPacks = {
        type: type,
        packs: SortPacks(filterPacks),
      };

      PacksArr.push(pack);
    });

    PacksArr.push({
      type: 'kombi',
      packs: SortPacks(packs.filter((pack) => pack.placement.toLowerCase() === 'kombi')),
    });

    setFullPacksArr(PacksArr);
  }

  function addPackCourses(selections: selectedCourses[], pack: PackStructure) {
    updatePackCourses(selections);
    updatePack(pack);
  }

  useEffect(() => {
    if (selFullCourses.length > 5) {
      setCoursePackArr([
        standardPackArr[standardPackArr.length - 1],
        ...standardPackArr.slice(0, standardPackArr.length - 1),
      ]);
    }
  }, [selFullCourses]);

  useEffect(() => {
    const type = shownSType === '' ? 'online' : shownSType;

    if (fullPacksArr.length > 0) {
      const fp = fullPacksArr.filter((pack) => pack.type === type);
      const vocationalPacks = fullPacksArr.filter(pack => pack.type === 'live')[0].packs.filter(pack => pack.vocationalPack);

      const packs = fp[0].packs;

      if (type === "online" || type === "campus") {
        setCoursePackArr([...packs, ...vocationalPacks]);
      } else {
        setCoursePackArr(packs);
      }


      setStandardPackArr(packs);
      setModalStates(Array(packs.length).fill(false));
    }
  }, [fullPacksArr, shownSType]);


  useEffect(() => {
    setFullPacks(coursePacks);
    selectStype();
  }, [coursePacks]);

  useEffect(() => {
    setSelectedPack(selPacks[0]);
    if (selPacks[0]) {
      setShownSType(selPacks[0].placement);
    }
  }, [selPacks]);

  useEffect(() => {
    if (selectedPack) {
      setSelectedPackTitle(selectedPack.title);
      setSelectedPackPLace(selectedPack.placement);
      setShownSType(selectedPack.placement);
    } else if (!selectedPack && relevantPackTitle !== '') {
      setSelectedPackTitle(relevantPackTitle);
      setSelectedPackPLace(shownSType);
    } else {
      setSelectedPackTitle('');
      setSelectedPackPLace(shownSType);
    }
  }, [relevantPackTitle, selectedPack]);

  useEffect(() => {
    const rpTitle = CheckIfPack(standardPackArr, shownSType, allStructuredCourses, selCourses);
    setRelevantPackTitle(rpTitle);
  }, [standardPackArr, shownSType, allStructuredCourses, selCourses]);


  useEffect(() => {
    const fullCourses = selCourses.filter(
      (sc) => sc.packCourse === false && !sc.courseCode.includes('intro') && !sc.courseCode.includes('lab')
    );
    setSelFullCourses(fullCourses);
  }, [selCourses]);


  // Rearrange the array so that the selected pack is first
  useEffect(() => {
    let selectedPackTitle = selectedPack?.title || '';
    let carouselPacks = [...coursePackArr];
    if (selectedPackTitle !== '') {
      const packIndex = carouselPacks.findIndex((p) => p.title === selectedPackTitle);
      if (packIndex !== -1) {
        let beforeIndex = carouselPacks.slice(0, packIndex);
        let followingIndex = carouselPacks.slice(packIndex);
        let rearrangedCarousel = [...followingIndex, ...beforeIndex];
        setCoursePackArr(rearrangedCarousel);
      }
    }
  }, [selectedPack]);


  useEffect(() => {
    // don't re-trigger scroll if user already has triggered it and adds or removes a course
    if (!previousLength || previousLength > 5) return;

    if (selFullCourses.length > 5) {
      const div = carouselDiv.current;
      if (div) {
        div.scrollLeft = 0;
      }
    }
  }, [selFullCourses]);

  function selectStype() {
    const schoolType = localStorage.getItem('schoolType') || '';
    setSType(schoolType);
    schoolType === 'kombi' || schoolType === '' ? setShownSType('online') : setShownSType(schoolType);
    const sPack = JSON.parse(localStorage.getItem('selectedPack') || '[]');
    if (sPack.length > 0) {
      if (sPack[0].placement !== schoolType) {
        setShownSType(sPack[0].placement);
      }
    }
  }

  function updateShownStype(SType: string) {
    setShownSType(SType);
  }

  const handleOpenModal = (index: number) => {
    const updatedModalStates = [...modalStates];
    updatedModalStates[index] = true;
    setModalStates(updatedModalStates);
  };

  const handleCloseModal = (index: number) => {
    const updatedModalStates = [...modalStates];
    updatedModalStates[index] = false;
    setModalStates(updatedModalStates);
  };

  const handlePrevious = () => {
    // Rearrange the array by moving the last element to the start
    setFilteredPacks((prevCoursePacks) => [
      prevCoursePacks[prevCoursePacks.length - 1],
      ...prevCoursePacks.slice(0, prevCoursePacks.length - 1),
    ]);
  };

  const handleNext = () => {
    // Rearrange the array by moving the first element to the end
    setFilteredPacks((prevCoursePacks) => [...prevCoursePacks.slice(1), prevCoursePacks[0]]);
  };

  const itemWidth = 100 / 4;

  useEffect(() => {
    if (filter === 'generalStudies') {
      setFilteredPacks([
        ...coursePackArr.filter((pack) => !pack.mathPack && !pack.vocationalPack),
        ...coursePackArr.filter((pack) => pack.mathPack || pack.vocationalPack),
      ]);
    }

    if (filter === 'vocationalStudies') {
      setFilteredPacks([
        ...coursePackArr.filter((pack) => pack.vocationalPack),
        ...coursePackArr.filter((pack) => !pack.vocationalPack),
      ]);
    }

    if (filter === 'math') {
      setFilteredPacks([
        ...coursePackArr.filter((pack) => pack.mathPack),
        ...coursePackArr.filter((pack) => !pack.mathPack && !pack.vocationalPack),
        ...coursePackArr.filter((pack) => pack.vocationalPack),
      ]);
    }
  }, [filter, coursePackArr]);


  return (
    <div>
      <div className='w-full self-start'>
        <div className='flex text-sm gap-1 py-4 items-center w-full xl:w-[32rem]'>
          <svg className='w-4 flex-shrink-0' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M8 18V12H10V14H18V16H10V18H8ZM0 16V14H6V16H0ZM4 12V10H0V8H4V6H6V12H4ZM8 10V8H18V10H8ZM12 6V0H14V2H18V4H14V6H12ZM0 4V2H10V4H0Z'
              fill='#1C1B1F'
            />
          </svg>
          <button
            onClick={() => {
              setFilter('generalStudies');
            }}
            className={twJoin(
              'w-full rounded-lg border border-black py-1 lg:py-2 px-4 shadow-md',
              filter === 'generalStudies' ? 'bg-sonans-primary' : 'bg-white'
            )}>
            <span className='hidden xl:block'>Studiespesialiserende</span>
            <span className='block xl:hidden'>Studiespes</span>
          </button>
          <button
            onClick={() => {
              setFilter('vocationalStudies');
            }}
            className={twJoin(
              'w-full rounded-lg border border-black py-1 lg:py-2 px-4 shadow-md',
              filter === 'vocationalStudies' ? 'bg-sonans-primary' : 'bg-white'
            )}>
            Yrkesfag
          </button>
          <button
            onClick={() => {
              setFilter('math');
            }}
            className={twJoin(
              'w-full rounded-lg border border-black py-1 lg:py-2 px-4 shadow-md',
              filter === 'math' ? 'bg-sonans-primary' : 'bg-white'
            )}>
            Matte
          </button>
        </div>
      </div>
      <div className='flex gap-2'>
        <ArrowButton arrowStyle='w-4' mode='carousel-left' onClick={handlePrevious} />
        <div
          ref={carouselDiv}
          className={'flex pb-2 pt-2 overflow-x-scroll tablet-s:overflow-x-hidden no-scrollbar overscroll-none'}>
          <div className={`flex flex-nowrap gap-2 justify-between`}>
            {filteredPacks.map((pack, index) => {
              let nonPackPrice = 0;
              let packPrice = 0;
              if (pack.alias !== 'sonans_pack') {
                nonPackPrice = getNonPackPrice(pack, courses, pack.vocationalPack ? "live" : shownSType, allStructuredCourses);
                packPrice = pack.price;
              }
              return (
                <div key={pack.alias} className={twJoin('w-[16rem] h-[90px] max-h-[90px] overflow-hidden')}>
                  <div
                    key={`${pack.alias}-card`}
                    onClick={() => handleOpenModal(index)}
                    className={twMerge(
                      `cursor-pointer hover:bg-bg-grey`,
                      selectedPackTitle === pack.title
                        ? `carouselCardSelected${
                            selectedPackPlace ? (pack.alias === 'sonans-pack' ? '' : `-${selectedPackPlace}`) : ''
                          }`
                        : 'carouselCard',
                      'border border-black',
                      filter === 'math' && pack.mathPack && selectedPackTitle !== pack.title && 'border-2 border-sonans-primary',
                      filter === 'vocationalStudies' && pack.vocationalPack && selectedPackTitle !== pack.title && 'border-2 border-sonans-primary',
                      filter === 'generalStudies' &&
                        !pack.mathPack &&
                        !pack.vocationalPack && selectedPackTitle !== pack.title &&
                        'border-2 border-sonans-primary'
                    )}>
                    <p className='font-bold text-sm'>{pack.title}</p>
                    {pack.alias !== 'sonans_pack' && packPrice < nonPackPrice && (
                      <div key={pack.alias + '-prices'}>
                        <p
                          key={pack.alias + '-beforeprice'}
                          className='text-xs tablet-s:text-sm line-through font-text-grey'>
                          {FormatPrice(nonPackPrice)},-
                        </p>
                        <p key={pack.alias + '-afterprice'} className='text-sm tablet-s:text-base'>
                          {FormatPrice(pack.price)},-
                        </p>
                      </div>
                    )}
                    {pack.alias !== 'sonans_pack' && packPrice >= nonPackPrice && (
                      <div>
                        <p className='text-xs tablet-s:text-sm line-through font-text-grey'></p>
                        <p className='text-sm tablet-s:text-base'>{FormatPrice(nonPackPrice)},-</p>
                      </div>
                    )}
                    {pack.alias === 'sonans_pack' && <p className='text-sm'>Velg 6 fag</p>}
                    {pack.alias === 'sonans_pack' && <p className='text-sm'>- få 15% rabatt</p>}
                  </div>
                  <Modal
                    id='pack_popup'
                    title={pack.title}
                    isOpen={modalStates[index]}
                    onClose={() => handleCloseModal(index)}>
                    <PackPopup
                      vocationalPack={!!pack.vocationalPack}
                      thisPack={pack}
                      allPacks={fullPacksArr}
                      courses={courses}
                      allStructuredCourses={allStructuredCourses}
                      selCourses={selCourses}
                      closeModal={() => handleCloseModal(index)}
                      updateCourses={(selections, pack) => addPackCourses(selections, pack)}
                      updateShownSType={(SType) => updateShownStype(SType)}
                    />
                  </Modal>
                </div>
              );
            })}
          </div>
        </div>
        <ArrowButton arrowStyle='w-4' mode='carousel-right' onClick={handleNext} />
      </div>
    </div>
  );
}
