import { fetchPlaces } from '../../Reducers/places';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState, ICourses, IPacks } from '../../../types';
import { useEffect } from 'react';
import { StructuredCourse, CourseVariants, PackStructure, PlacesStructure } from '../../../types';
import { ShortPackNames } from '../Translators/ShortPackNames';

export const vocationalCourses = [
  { name: 'Vg1 Helse og oppvekstfag', courseCodes: ['HSF1006', 'HSF1007', 'HSF1008', 'HSF1009'] },
  { name: 'Vg2 Helseservicefag', courseCodes: ['HES2005', 'HES2006', 'HES2007', 'HES2008'] },
  { name: 'Vg3 Helsesekretær', courseCodes: ['KRO1006', 'HSE3005', 'HSE3006', 'HSE3007', 'HSE3008'] },
  { name: 'Vg2 Helsearbeiderfag', courseCodes: ['HEA2005', 'HEA2006', 'HEA2007', 'HEA2008'] },
  { name: 'Vg2 Barne- og ungdomsarbeider', courseCodes: ['BUA2005', 'BUA2006', 'BUA2007', 'BUA2008'] },
  { name: 'Praksiskandidat - helsefag', courseCodes: ['HEA3103', 'BUA3103'] },
];

const vocationalPackageNames = [
  'Vg1 Helse og oppvekstfag',
  'Vg2 Helseservicefag',
  'Vg3 Helsesekretær',
  'Vg2 Helsearbeiderfag',
  'Vg2 Barne- og ungdomsarbeider',
  'Praksiskandidat Barne- og undgsomsarbeider',
  'Praksiskandidat Helsefagarbeider',
];

const mathPackNames = ['Matte R1+R2', 'Matte S1+S2'];

const vocationalCoursesCodes = vocationalCourses.map((course) => course.courseCodes).flat();

export function GetStructuredPacks(packs: IPacks[]) {
  let structuredPacks: PackStructure[] = [] as PackStructure[];

  packs.forEach((pack) => {
    const name = pack.name;
    const nameStripped: String = ShortPackNames(name);
    const price: Number = pack.price;

    const currentPack: PackStructure = {
      title: nameStripped,
      price: price,
      courses: pack.courses,
      placement: pack.placement,
      agreements: pack.agreements,
      lookup: pack.lookup,
      alias: pack.package_alias,
      vocationalPack: false,
    } as PackStructure;

    if (vocationalPackageNames.includes(currentPack.title)) {
      currentPack.vocationalPack = true;
    }

    if (mathPackNames.includes(currentPack.title)) {
      currentPack.mathPack = true;
    }

    structuredPacks.push(currentPack);
  });
  const agreements = [
    'sonpridra',
    'sonpriton',
    'sonpriosl',
    'sonpriber',
    'sonans',
    'sonpritrom',
    'sonprikri',
    'sondig',
    'sonprista',
    'sonpripor',
    'sonpriham',
    'sonprifre',
    'sonpribod',
    'sonprisan',
    'sonprilil',
    'sonpriski',
    'sonpritro',
  ];
  const placements = ['campus', 'live', 'online'];

  placements.forEach((p) => {
    const SonansPack: PackStructure = {
      title: 'Sonanspakken',
      price: 0,
      placement: p,
      agreements: agreements,
      alias: 'sonans_pack',
    } as PackStructure;

    structuredPacks.push(SonansPack);
  });

  return structuredPacks;
}

export function GetStructuredCourses(courses: ICourses[]) {
  let structuredCourses: StructuredCourse[] = [] as StructuredCourse[];
  let courseIds: any = [];
  let courseCodes: string[] = [];
  let subjects: any = [];
  courses.forEach((course) => {
    subjects = [...subjects, ...course.subjects];
  });

  subjects.forEach((c1: any) => {
    let courseCode = c1.course_code;
    if (
      courseCodes.indexOf(courseCode) === -1 &&
      c1.dummy_parent_course_id === 'false' &&
      c1.has_new_course === 'false' &&
      courseCode !== '' &&
      courseCode !== null
    ) {
      courseCodes.push(courseCode);
    }
  });

  courseCodes.forEach((cc) => {
    let places: string[] = [];
    let times: string[] = [];
    let title: string = '';
    let variants: CourseVariants[] = [];
    let lab: CourseVariants[] = [];
    let upgrades: CourseVariants[] = [];
    let upgradePlaces: string[] = [];

    subjects.forEach((sub: any) => {
      if (
        (sub.course_code === cc || sub.course_code === `lab-${cc}`) &&
        sub.dummy_parent_course_id === 'false' &&
        sub.has_new_course === 'false'
      ) {
        let name = sub.title;
        const nameStripped: string = name
          .replace(' online', '')
          .replace(' live', '')
          .replace('Grunnleggende matte - f', 'F')
          .replace('Grunnleggende VGS-matte - f', 'F');
        title = nameStripped;
        if (times.indexOf(sub.time) === -1) {
          times.push(sub.time);
        }
        sub.places.forEach((place: string) => {
          if (places.indexOf(place) === -1) {
            places.push(place);
          }
        });
        if (nameStripped.includes('teskje') || nameStripped.includes('Bestått')) {
          sub.places.forEach((place: string) => {
            if (upgradePlaces.indexOf(place) === -1) {
              upgradePlaces.push(place);
            }
          });
        }

        courseIds.push(sub.course_id);

        const currentCourse: CourseVariants = {
          courseId: sub.course_id,
          courseTitle: nameStripped,
          courseCode: sub.course_code,
          price: sub.price,
          priceNetFullyear: sub.online_year_price,
          priceNetHalfyear: sub.online_half_year_price,
          time: sub.time,
          places: sub.places,
          yearHours: sub.year_hours,
        } as CourseVariants;

        if (
          sub.time === 'lab_practice_spring' ||
          sub.time === 'lab_practice_autum' ||
          sub.course_code.includes('lab-')
        ) {
          lab.push(currentCourse);
        } else if (nameStripped.includes('teskje') || nameStripped.includes('Bestått')) {
          upgrades.push(currentCourse);
        } else {
          variants.push(currentCourse);
        }
      }
    });

    if (cc.includes('lab-')) {
      variants = lab;
      lab = [];
    }

    const courseTitle: string = title
      .replace(' med teskje', '')
      .replace(' Bestått', '')
      .replace(' forberedende til', 'forkurs')
      .replace('Forberedende til', 'Forkurs');

    let currentSub: StructuredCourse = {
      courseCode: cc,
      courseTitle: courseTitle,
      variants: variants,
      labCourses: lab,
      upgradeCourses: upgrades,
      upgradePlaces: upgradePlaces,
      times: times,
      places: places,
    } as StructuredCourse;

    if (vocationalCoursesCodes.includes(cc)) {
      currentSub.vocationalSubject = true;

      vocationalCourses.forEach((vocationalCourse) => {
        if (vocationalCourse.courseCodes.includes(cc)) {
          currentSub.vocationalCategory = vocationalCourse.name;
        }
      });
    }

    structuredCourses.push(currentSub);
  });

  return structuredCourses;
}

export function GetStructuredLocations() {
  const dispatch = useDispatch<AppDispatch>();
  function getPlaces() {
    dispatch(fetchPlaces());
  }
  useEffect(() => getPlaces(), []);
  const { places } = useSelector((state: AppState) => state.places);
  let structuredPlaces: PlacesStructure[] = [] as PlacesStructure[];
  places.forEach((place) => {
    if (place.placement === 'campus') {
      const currentPlace: PlacesStructure = {
        agreement_id: place.agreement_id,
        alias: place.alias,
        name: place.name,
      } as PlacesStructure;

      structuredPlaces.push(currentPlace);
    }
  });

  return structuredPlaces;
}
