import { ICourse, ICourses, IconTypes, PackStructure, PackStructureCourse, StructuredCourse, selectedCourses } from "../../../types"
import { getSemesterPackCourses } from "../GetText/libraries/GetPacks";
import { findCourseCode, findOfferedPackageCourse } from "../PackageCoursePicker/PackageCoursePicker";
import { CourseFromId } from "../Translators/CourseFromId";
import { FindOppositeSemester } from "./CheckSemester";
import { getFirstSem } from "./GetAvailableSemesters";

export function getNonPackPrice(pack: PackStructure, courses: ICourses[], schoolType: string, allCoursesStructured: StructuredCourse[]){
    const pCourses = pack.courses as PackStructureCourse[];
    const placement = schoolType as IconTypes
    let schoolAlias = localStorage.getItem('schoolAlias') || 'sonans';
    if(schoolType === 'online') {
        schoolAlias = 'sonans'
    } else if(schoolType === 'live') {
        schoolAlias = 'sondig'
    }

    const packTitle = pack.title.replace(' online', '').replace(' live', '');
    const isPrevSem = JSON.parse(localStorage.getItem('prevSem') || 'false')
    let firstSem = getFirstSem();
    if(isPrevSem) {
        firstSem = FindOppositeSemester(firstSem)
    }
    const getPackCourses = getSemesterPackCourses(packTitle, schoolAlias, firstSem)

    let foundCourses: selectedCourses[] = [];
    pCourses.forEach((pc, i) => {
        const courseCode = findCourseCode(pCourses[i], allCoursesStructured)
        const getPackCourse = getPackCourses?.filter((c) => c.coursecode === courseCode)
        let place = schoolType;
        let selPackCourse = { ...pCourses[i]}
        if(getPackCourse?.length > 0) {
            place = getPackCourse[0].place
            if(place === 'online') {
                selPackCourse.alternative_type = getPackCourse[0].semester
                selPackCourse.semester_length_alias = 'netbased'
            } else {
                selPackCourse.semester_length_alias = getPackCourse[0].semester
            }
        }
        const course = findOfferedPackageCourse(selPackCourse, placement, allCoursesStructured, schoolAlias, pCourses)
        if(course) {
            foundCourses.push(course)
        }
    })
    
    const labCourses = foundCourses.filter((l) => { 
        if(l.courseCode.includes('lab')){
            return l
        }
    })
    
    const labToRemove = labCourses.filter((l) => {
        const cc = l.courseCode.replace('lab-', '')
        const mainCourse = foundCourses.filter((fc) => fc.courseCode === cc)
        if (mainCourse[0].type === 'online'){
            return l
        }
    })

    let price: number = 0;
    foundCourses.forEach((fc) => {
        if(labToRemove.indexOf(fc) === -1){
            price += fc.selectedPrice
        }
        
    })

    /* let packCourses: ICourse[] = []
    pCourses.forEach((pc) => {
        const c = CourseFromId(pc.course_id, pc.semester_length_alias, courses)
        if(c && c !== 'not found'){
            packCourses.push(c)
        }
    });

    let price: number = 0;
    packCourses.forEach((packC) => {
        const pc = pCourses.filter(p => p.course_id === packC.course_id)
        if(schoolType === 'online' && pc[0].alternative_type === 'year'){
            price = price + packC.online_year_price
        } else if (schoolType === 'online' && pc[0].alternative_type !== 'year'){
            price = price + packC.online_half_year_price
        } else {
            price = price + packC.price
        }
    }) */
    
    return price;

}