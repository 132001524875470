const GetCourseInfo: { [key: string]: (string | string[])[] } = {
  SAM3073: [
    'Sammen med Psykologi 1 utgjør Psykologi 2 en fordypning innen samfunnsfag, språk og økonomi.',
    'I Psykologi 2 vil du få forståelse for hvordan grupper fungerer og hvordan omgivelsene kan påvirke måten vi oppfører oss på, både gjennom kommunikasjon og andre sosiale prosesser. Du vil også få litt innsikt i hva psykisk helse og psykiske lidelser er, og hvordan de henger sammen.',
  ],
  REL1003: [
    'Er du ute etter vitnemål fra studiespesialiserende, må du ha eksamen i Religion og etikk.',
    'Religion og etikk er et fag hvor du skal lære om et bredt utvalg av religioner og livssyn. Videre skal du lære mer om humanisme, etikk og filosofi. Disse fagkunnskapene skal forberede deg på å diskutere samfunnsutviklinger, menneskerettigheter og problemer slik som rasisme og diskriminering. Sentrale fokus vil ligge på for eksempel urfolk og mediers rolle i denne sammenhengen.',
    'Religion og etikk skal gi grunnlag for at du kan reflektere over hvordan vi lever sammen med ulike verdier, holdninger og livssyn. Faget skal bidra til at du får reflektere over grunnleggende verdier som menneskeverd og respekt for naturen. Faget bidrar også til å utdype din demokratiforståelse ved å gi deg kunnskap om religion og livssyn i et majoritets-, minoritets- og urfolksperspektiv. Faget gir deg øvelse i etisk refleksjon og bidrar til å utvikle din dømmekraft.',
  ],
  'NOR1267/NOR1268/NOR1269': [
    'For å få generell studiekompetanse så må du ha norskfaget. Norsk er både et viktig fag og et nyttig redskap til å gjøre det godt i andre fag.',
    'I norskfaget lærer du om språket som system, hvordan språket utvikler og endrer seg, og hvordan man kan bruke språk og bilder både kunstnerisk og til å overbevise. Faget tar også for seg litteraturen og litteraturhistorien, og du lærer å tolke og å forstå ulike typer tekster.',
    'Du kan ta norsk muntlig og skriftlig, uansett om du trenger påbygg eller skal fullføre studiespesialiserende vitnemål. Vi anbefaler at du tar Norsk over et helt år.',
    'I norsk må du opp i 3 eksamener: hovedmål skriftlig, sidemål skriftlig og norsk muntlig.',
  ],
  NAT1007: [
    'Du må ha naturfag for å oppnå generell studiekompetanse.',
    'Naturfag er byggesteinen for realfagene. Behersker du det viktigste i naturfag, er du godt rustet til å gjøre ditt beste i biologi, kjemi og fysikk.',
    'Vi anbefaler at du også tar et labkurs i naturfag slik at du er forberedt til muntlig-praktisk eksamen.',
  ],
  NAT1018: [
    'Du må ha naturfag for å oppnå generell studiekompetanse.',
    'Naturfag er byggesteinen for realfagene. Behersker du det viktigste i naturfag, er du godt rustet til å gjøre ditt beste i biologi, kjemi og fysikk.',
    'Velger du å heller ta Naturfag VG1 studiespesialiserende vil den nye karakteren dekke kravet for påbygg samt erstatte karakteren du har i Naturfag fra VG1 yrkesfag. Velger du Naturfag påbygg kommer karakteren i tillegg til karakteren din fra VG1 yrkesfag.',
    'Vi anbefaler at du også tar et labkurs i naturfag slik at du er forberedt til muntlig-praktisk eksamen.',
  ],
  REA3062: [
    'Skal du studere økonomi eller medisin? Da kan matte S2 være noe for deg.',
    'I Matematikk S2 går du videre fra S1. Du lærer mer om rekker, derivasjon, økonomi og statistikk.',
    'Matematikk S2 er et programfag fra studiespesialiserende og gir 0,5 realfagspoeng. Anbefalte forkunnskaper er Matematikk S1.',
    'Mange studier har krav om Matematikk S1 + S2 eller Matematikk R1.',
  ],
  REA3028: [
    'Skal du studere økonomi eller medisin? Da kan matte S2 være noe for deg.',
    'I Matematikk S2 går du videre fra S1. Du lærer mer om rekker, derivasjon, økonomi og statistikk.',
    'Matematikk S2 er et programfag fra studiespesialiserende og gir 0,5 realfagspoeng. Anbefalte forkunnskaper er Matematikk S1.',
    'Mange studier har krav om Matematikk S1 + S2 eller Matematikk R1.',
  ],
  REA3058: [
    'Skal du studere arkitektur eller ingeniørfag? Da må du ha Matematikk R2.',
    'R2 pensum dekker blant annet integralregning, rekker og trigonometri. Du går videre fra Matematikk R1 og lærer mer om vektorregning.',
    'Matematikk R2 er et programfag i studiespesialiserende programområde og gir 1 realfagspoeng.',
    'Matematikk R1, eller S1 + S2, er nødvendige forkunnskaper til R2 matte.',
  ],
  REA3024: [
    'Skal du studere arkitektur eller ingeniørfag? Da må du ha Matematikk R2.',
    'R2 pensum dekker blant annet integralregning, rekker og trigonometri. Du går videre fra Matematikk R1 og lærer mer om vektorregning.',
    'Matematikk R2 er et programfag i studiespesialiserende programområde og gir 1 realfagspoeng.',
    'Matematikk R1, eller S1 + S2, er nødvendige forkunnskaper til R2 matte.',
  ],

  MAT1151: [
    'Har du gått yrkesfag og skal ta påbygg til generell studiekompetanse? Da trenger du Matematikk 2P-Y.',
    'I dette kurset går du videre fra 1P-Y og lærer mer om potensregning, statistikk og funksjoner. Du lærer også hvordan du lager matematiske modeller.',
    'Du må ha tatt Matematikk 1P-Y tidligere for å ta Matematikk 2P-Y. For å få generell studiekompetanse må du ha Matematikk 1P-Y og 2P-Y.',
  ],
  MAT1005: [
    'Har du gått yrkesfag og skal ta påbygg til generell studiekompetanse? Da trenger du Matematikk 2P-Y.',
    'I dette kurset går du videre fra 1P-Y og lærer mer om potensregning, statistikk og funksjoner. Du lærer også hvordan du lager matematiske modeller.',
    'Du må ha tatt Matematikk 1P-Y tidligere for å ta Matematikk 2P-Y. For å få generell studiekompetanse må du ha Matematikk 1P-Y og 2P-Y.',
  ],
  KRO1019: [
    'For å få vitnemål fra studiespesialiserende må du ha Kroppsøving.',
    'I kroppsøving lærer du hvordan du trener smartest og det viktigste om store og små idretter.',
    'Kroppsøving er et teoretisk fag du har praktisk nytte av. Du lærer blant annet om forskjellige idretter, hva som påvirker helsen din og hvordan du kan unngå skader.',
  ],
  'REA3046/REA3047': [
    'Vil du bli lege, tannlege eller veterinær så må du ha Kjemi 2.',
    'I Kjemi 2 går vi mer analytisk til verks enn i Kjemi 1. Du skal lære om de store kjemiområdene, organisk kjemi, uorganisk kjemi og analyse innen disse.',
    'Kjemi 2 er et programfag i studiespesialiserende studieretning og sammen med Kjemi 1 utgjør det en fordypning i realfag.',
    'Vi anbefaler at du gjennomfører et labkurs i kjemi 2, slik at du er godt forberedt til den praktiske delen av eksamen.',
    'Faget gir 0,5 realfagspoeng.',
  ],
  HIS1010: [
    'Du må ha historie for å oppnå generell studiekompetanse.',
    'Dersom du skal ta påbygging til generell studiekompetanse eller benytte 23/5 regelen, kan du ta Historie vg3 påbygg. På et studiespesialiserende vitnemål må du ha Historie vg3.',
    'Historieundervisningen hos Sonans gir deg viktig innsikt i verdens- og norgeshistorien, helt fra antikken og frem til i dag.',
  ],
  HIS1011: [
    'Du må ha historie for å oppnå generell studiekompetanse.',
    'Dersom du skal ta påbygging til generell studiekompetanse eller benytte 23/5 regelen, kan du ta Historie vg3 påbygg. På et studiespesialiserende vitnemål må du ha Historie vg3.',
    'Historieundervisningen hos Sonans gir deg viktig innsikt i verdens- og norgeshistorien, helt fra antikken og frem til i dag.',
  ],
  'REA3043/REA3044': [
    'Geofag 2 gir mer innsikt i klima og klimaendringer. Sammen med Geofag 1 dekker Geofag 2 en fagfordypning innen studiespesialisering med realfag.',
    'Du lærer om vekselvirkninger mellom hav og atmosfæren samt modeller som beskriver endringer av klimaet. Videre vil man få kunnskap om hvordan energiressurser fra hav og atmosfære kan utnyttes på en bærekraftig måte.',
    'Vi anbefaler at du har Geografi og/eller Geofag 1 fra før av, eller at du leser en av disse parallelt med Geofag 2.',
    'Faget gir 0,5 realfagspoeng.',
  ],
  'REA3039/REA3040': [
    'I Fysikk 2 går du dypere inn i stoffet enn i Fysikk 1.',
    'I Fysikk 2 lærer om den klassiske og den moderne fysikken og hvordan vi bruker matematikk og matematiske modeller til å beskrive naturen.',
    'Vi anbefaler også at du deltar på labkurs, slik at du er trygg på det praktiske til eksamen.',
    'Fysikk 2 er et programfag i studiespesialiserende studieretning og gir 1 realfagspoeng.',
    'Sammen med Fysikk 1 utgjør Fysikk 2 en realfaglig fordypning.',
  ],
  'REA3036/REA3037': [
    'Biologi 2 er lurt å ta hvis du skal studere biologi eller helsefag som medisin og odontologi.',
    'Kurset tar for seg emner som energiomsetning, genetikk, genteknologi, økologi, evolusjon og menneskets påvirkning på naturen.',
    'Biologi 2 gir deg 0,5 realfagspoeng.',
    'Vi anbefaler også at du deltar på labkurs, slik at du er trygg på det praktiske til eksamen.',
    'Det er en fordel, at du har fullført Biologi 1 først, eller tar det samtidig.',
    'Sammen med Biologi 1 utgjør Biologi 2 en realfaglig fordypning i studieforberedende utdanningsprogram.',
  ],
  SAM3055: [
    'Politikk og menneskerettigheter gir deg grunnleggende kjennskap til norsk og internasjonal politikk, og om demokratiske prinsipper og hvordan disse praktiseres i Norge og andre land.',
    'Sammen med Sosiologi og sosialantropologi utgjør Politikk og menneskerettigheter en fordypning innen samfunnsfag, språk og økonomi.',
  ],
  SAM3058: [
    'Rettslære 2 er delt inn i hovedområdene erstatningsrett, menneskerettigheter, strafferett, miljørett og forvaltningsrett.',
    'Sammen med Rettslære 1, dekker Rettslære 2 en fordypning fra studiespesialiserende innen samfunnsfag, språk og økonomi.',
  ],
  'REA3049/REA3050': [
    'Informasjonsteknologi 2 gir deg et godt utgangspunkt for videre IT-studier, eller andre realfagsretninger.',
    'I Informasjonsteknologi 2 lærer du å bruke logisk tenkning til å løse ulike problemstillinger. Problemstillingene strekker seg fra beregninger til animasjoner.',
    'Det anbefales å ha gjennomført Informasjonsteknologi 1, eller ha erfaring innen programmering. Faget kan tas uavhengig av Informasjonsteknologi 1.',
    'Informasjonsteknologi 2 er et programfag i studiespesialiserende programområdet og gir 0,5 realfagspoeng.',
  ],
  'SPR3031/SPR3032': [
    'Du lærer om politiske, sosiale og økonomiske forhold i USA, Storbritannia og andre engelskspråklige land. I tillegg får du innsikt i historiske hendelser og konflikter som har formet disse samfunnene.',
    'Sammen med Engelsk 1, dekker Engelsk 2 en fordypning i studiespesialisering innen samfunnsfag, språk og økonomi.',
    'Du må ha Engelsk vg1 fra før. Det anbefales også å ha hatt Engelsk 1 (vg2).',
    'Engelsk 2 er et nytt fag i Fagfornyelsen og <em>erstatter</em> både Samfunnsfaglig engelsk og Engelskspråklig litteratur og kultur. <strong>Dersom du ønsker å forbedre karakter i Samfunnsfaglig engelsk eller Engelskspråklig litteratur og kultur, må du ta opp Engelsk 2 muntlig og skriftlig. Merk at du må be din VGS om et oppdatert vitnemål når du er ferdig som privatist.</strong>',
  ],
  MAT1019: [
    'Matematikk 1P er et praktisk matematikkfag som er nødvendig for videre studier. Du lærer blant annet om tallregning, formelregning og funksjoner.',
    'For å få generell studiekompetanse, må du ha Matematikk 1P og 2P.',
    'Hvis du trenger å friske opp matematikk fra ungdomskolen så anbefaler at du tar et forkurs i matematikk.',
  ],
  SAM3051: [
    'Er du interessert i hvordan samfunnet fungerer og hvordan vi som individer utvikler oss og har ulike behov i livets forskjellige faser? Da er Sosialkunnskap noe for deg.',
    'I Sosialkunnskap lærer du om sentrale verdier som demokrati, mangfold, inkludering og likestilling. Du får innblikk i hvordan velferdsstaten vår er bygd opp, og lærer om hvilke utfordringer den står overfor fremover.',
    'Du får også lære om samfunnsvitenskapelige arbeidsmetoder og om teoretiske perspektiver på samfunnet og psykososial utvikling.',
    'Vi ser også på årsaker til helse- og velferdsforskjeller, og hvordan marginalisering og utenforskap i arbeids- og privatliv kan forstås og forebygges.',
    'Sosialkunnskap er et av fagene du kan ta som del av en fordypning når du tar studiespesialisering innen samfunnsfag, språk og økonomi.',
  ],
  SAM3053: [
    'Globale endringer får svært ulike konsekvenser fra et sted til et annet. Hva er det ved stedene som gjør at det er slik? Samfunnsgeografi gir svarene.',
    'Svarene dreier seg ofte om økonomiske, politiske og kulturelle forhold på stedet. Kanskje en kombinasjon. Det er dette du lærer om.',
    'Du får også innsikt i befolkningspolitikk, flyttestrømmer, region- og byutvikling og utviklingsteorier.',
    'Det anbefales at du har geografi fra før av eller at du tar det parallelt.',
    'Samfunnsgeografi bygger bygger i stor grad på Samfunnskunnskap, du kan med fordel ta disse samtidig.',
    'Sammen med Sosialkunnskap eller Politikk og menneskerettigheter, dekker Samfunnsgeografi en fordypning i studiespesialisering innen samfunnsfag, språk og økonomi.',
  ],
  REA3048: [
    'Informasjonsteknologi 1 gir deg en bred innføring i ulike områder innen IT.',
    'Informasjonsteknologi 1 består av hovedområdene; kreativ problemløsing, teknologi, individ og samfunn, modellering og programmering, og digital representasjon og informasjonsutveksling. Du trenger ingen forkunnskaper for å kunne ta dette kurset.',
    'Informasjonsteknologi 1 er et programfag i studiespesialiserende studieretning og gir 0,5 realfagspoeng.',
  ],
  'SPR3029/SPR3030': [
    'I Engelsk 1 skal du videreutvikle dine språkferdigheter i muntlig og skriftlig engelsk. Det legges blant annet vekt på kunnskap om kultur i den engelskspråklige verden, kildekritikk og faglig refleksjon.',
    'I dette faget skal du også reflektere over ulike varianter av det engelske språket, samt innflytelsen det engelske språket har i verden.',
    'Blant andre temaer skal du kjenne til aktuelle samfunnsdebatter i engelskspråklige land, og fordype deg i minst ett tema knyttet til språk, kultur, samfunn eller vitenskap.',
    'Sammen med Engelsk 2, dekker Engelsk 1 en fordypning i studiespesialisering innen samfunnsfag, språk og økonomi.',
    'Du må ha Engelsk VG1 fra før for å kunne ta Engelsk 1.',
    'Engelsk 1 erstatter Internasjonal engelsk i Fagfornyelsen.',
  ],
  SAK1001: [
    'Samfunnskunnskap er et sentralt fag der du får grunnleggende kunnskap om hvordan samfunnet fungerer. Du må ha Samfunnskunnskap for å få generell studiekompetanse.',
    'Du lærer å forstå og finne fram i samfunnet. Kompetansemålene i Samfunnskunnskap legger vekt på perspektivmangfold, livsmestring, kildekritisk tilnærming, bærekraft og demokratisk kompetanse.',
    'I den samfunnsøkonomiske delen av faget lærer du blant annet om arbeidslivet, arbeidsledighet og bedriftsetablering, og får innsikt i internasjonal produksjon og forbruk.',
    'Samfunnskunnskap erstattet Samfunnsfag våren 2021.',
  ],
  'FSP6222/FSP6223': [
    'I spansk II lærer du å beherske spansk på et grunnleggende nivå både skriftlig og muntlig. I tillegg får du innsikt i samfunnet, historien, geografi og kulturen i spansktalende land.',
    'Spansk II er ett av fagene du kan ta for å oppfylle kravet til 225 årstimer valgfritt fremmedspråk på videregående.',
    'Faget er for deg som skal forbedre eller fullføre Spansk II fra videregående eller som har hatt spansk på ungdomsskolen og vil fortsette med språket på videregående nivå.',
    'Som privatist må du både ta spansk muntlig eksamen og skriftlig spansk eksamen.',
  ],
  SAM3054: [
    'Sosiologi og sosialantropologi er faget for deg som vil forstå mer av hva som driver samfunnsutviklingen og former oss som individer og samfunnsmedlemmer.',
    'Faget gir deg innsikt i ulike forskningsfelt og aktuell debatt. Kompetansemålene i Sosiologi og sosialantropologi dekker blant annet sosialisering, kulturforståelse og maktforskjeller i samfunnet.',
    'I Fagfornyelsen har også temaer som demokratisk medborgerskap, kulturelt mangfold og teknologisk endring fått en viktig plass. Du skal lære deg å tenke vitenskapelig, kunnskapsbasert og kritisk om aktuelle problemstillinger både i Norge og internasjonalt, og bli bevisst om hvordan identitet og tilhørighet dannes i det moderne samfunnet.',
    'Sammen med Sosialkunnskap eller Politikk og menneskerettigheter dekker faget en fordypning i studiespesialisering innen samfunnsfag, språk og økonomi.',
  ],
  MAT1021: [
    'Matte 1T gir deg de nødvendige forkunnskapene til Matematikk R1, noe du trenger hvis du skal studere medisin, teknologi eller naturvitenskapelige fag.',
    'I 1T matte lærer du å bruke matematikk til problemløsing og utforsking, gjennom regnestrategier, bruk av digitale verktøy og programmering. Sentrale tema i kompetansemålene er problemløsing ved bruk av faktorisering, ligninger, ligningssystem og ulikheter, funksjoner og funksjonsdrøfting, og trigonometri.',
  ],
  REA3060: [
    'I Matematikk S1 lærer du blant annet å utforske og forstå regneregler for potenser og logaritmer. Du lærer også å bruke digitale verktøy til å simulere og utforske utfall i stokastiske forsøk.',
    'S1 gir 0,5 realfagspoeng. Nødvendige forkunnskaper for S1 matte er Matematikk 1T eller 1P. Kommer du fra 1P må du belage deg på å jobbe litt mer med faget.',
    'Kombinasjonen Matematikk S1 + S2 kan også erstatte Matematikk R1 hvis du søker enkelte studier som for eksempel medisinstudiet.',
  ],
  REA3045: [
    'I Kjemi 1 blir du kjent med atomer og stoffene de bygger opp.',
    'Du lærer blant annet om atommodeller, kjemiske bindinger og får grunnleggende regneferdigheter kalt «støkiometri» på kjemispråket. Undervisningen fordyper seg deretter i temaer som likevekter, analysemetoder, organisk kjemi, syrer og baser.',
    'Kjemi 1 er et programfag i studiespesialiserende studieretning og gir 0,5 realfagspoeng.',
    'Vi anbefaler at du gjennomfører et labkurs i Kjemi 1, slik at du er godt forberedt til den praktiske delen av eksamen.',
  ],
  REA3042: [
    'Geologi, naturgeografi og geofysikk er viktige deler av geofag. Sammen med Geofag 2 dekker Geofag 1 en fordypning innen studiespesialisering med realfag.',
    'I Geofag 1 lærer du om dannelsen av jorda, kontinentene, bergarter og bergartssyklusen. Vi ser også på vekselvirkningene mellom ulike jordsystemer, og hvordan disse påvirker geosfæren og hydrosfæren. Viktige momenter i geofag er hvordan diverse systemer påvirkes av menneskelig aktivitet og hvordan vi kan utnytte geologiske ressurser på en bærekraftig måte.',
    'Vi anbefaler at du har Geografi fra før av, eller at du tar det parallelt med Geofag 1. Faget gir 0,5 realfagspoeng.',
  ],
  REA3056: [
    'Matematikk R1 er et teoretisk matematikkfag som gir deg det matematiske grunnlaget du trenger i fysikk og kjemi. Kompetansemålene dekker blant annet logaritmer, derivasjon, geometri, vektorer og funksjoner.',
    'Matematikk R1 er et programfag fra studiespesialiserende og gir deg 0,5 realfagspoeng.',
    'Matematikk R1 kan gi generell studiekompetanse i kombinasjon med Matematikk 1T.',
    'Vi anbefaler at du har Matematikk 1T fra før.',
  ],
  REA3038: [
    'I Fysikk 1 blir du kjent med blant annet energi og bevegelse, atom og kjernefysikk og elektrisitet. Vi skal også lære om universet og astrofysikk.',
    'Du lærer om fysikkens begreper, symboler og språk, og du får en innføring i praktisk laboratoriearbeid.',
    'Fysikk 1 er et programfag i studiespesialiserende studieretning og gir 0,5 realfagspoeng.',
    'Vi anbefaler også at du deltar på labkurs, slik at du er trygg på det praktiske til eksamen.',
  ],
  REA3035: [
    'Skal du studere biologi, medisin eller odontologi, bør du ha Biologi 1.',
    'I Biologi 1 lærer du blant annet om biologisk systematikk og hvordan det biologiske mangfoldet på jorda trues av klimaendringer og arealbruk. Du lærer hvordan celler fungerer, sammenhengen mellom fysiologi og anatomi, samt hvordan livsprosesser gjennomføres i ulike organismer. Faget tar også for seg hvordan sykdom oppstår og bekjempes, effekten av vaksiner og antibiotika, og hvordan hormoner og nerver regulerer kroppens funksjoner.',
    'Biologi 1 gir deg 0,5 realfagspoeng.',
    'Vi anbefaler å ta labkurs i Biologi 1 da eksamen er muntlig-praktisk.',
  ],
  MAT1023: [
    'Matematikk 2P gir deg generell kompetanse i matematikk. Dette er kunnskap du trenger både i hverdagen og i videre studier.',
    'I dette kurset går du videre fra Matematikk 1P og lærer mer om prosentregning og likninger. Du lærer også om hvordan du lager matematiske modeller.',
    'Vi anbefaler at du har tatt Matematikk 1P tidligere.',
  ],
  SAM3072: [
    'Om du skal studere psykologi, eller bare er nysgjerrig på årsakene til at folk oppfører seg som de gjør, er Psykologi 1 faget for deg.',
    'I Psykologi 1 lærer du om hvilke faktorer innen oppvekst, samfunn og biologi som påvirker hvordan mennesker tenker, føler og oppfører seg. Du blir kjent med emner som utviklingspsykologi, læringspsykologi og biologisk psykologi.',
    'Sammen med Psykologi 2 dekker Psykologi 1 en fordypning i studiespesialisering innen samfunnsfag, språk og økonomi.',
  ],
  SAM3057: [
    'Rettslære tar for seg de viktigste rettsreglene i samfunnet, hvordan du anvender Norges lover og hvordan rettsapparatet fungerer. Med andre ord; viktig kunnskap du har nytte av i hverdagen.',
    'Faget er delt inn i hovedområdene privatrett, kjøps- og avtalerett og arbeidsrett.',
    'Privatretten tar blant annet for seg familierett, barnerett og arverett. Kjøps- og avtalerett handler om rettigheter du har som forbruker, og hva som skal til for at en avtale er bindende. I arbeidsrett lærer du om rettigheter og plikter i arbeidslivet - og likestilling.',
    'Sammen med Rettslære 2 dekker Rettslære 1 en fordypning i det studiespesialiserende programområdet samfunnsfag, språk og økonomi.',
  ],
  'PSP5828/PSP5829': [
    'Du lærer å beherske grunnleggende spansk skriftlig og muntlig, og får innsikt i historie, geografi, kultur og samfunn i spansktalende land.',
    'Sammen med Spansk I programfag utgjør faget én av to valgfrie fordypninger innenfor programområde for samfunnsfag, språk og økonomi. Hver fordypning skal ha minst 280 årstimer over to år.',
    'Spansk II programfag er for deg som har fullført Spansk I som obligatorisk fellesfag eller som programfag.',
    'Spansk II programfag kan også tas som poenggivende fag for søkere etter 23/5-regelen eller påbygg til generell studiekompetanse. Du får 0,5 språkpoeng.',
  ],
  'PSP5826/PSP5827': [
    'Du lærer deg grunnleggende spansk både skriftlig og muntlig. Samtidig får du innsikt i samfunnet og kulturen i spansktalende land.',
    'Spansk I og II programfag utgjør én av to valgfrie fordypninger innenfor programområde for samfunnsfag, språk og økonomi. Hvert fag skal ha minst 280 timer over to år.',
    'Spansk I programfag er for deg som ha fullført fremmedspråk som obligatorisk fellesfag med et annet språk, for eksempel Fransk.',
    'Spansk I programfag kan også tas som poenggivende fag for søkere etter 23/5-regelen eller påbygg til generell studiekompetanse. Du får 0,5 språkpoeng.',
  ],
  'PSP5838/PSP5839': [
    'I Tysk I lærer du deg grunnleggende skriftlig og muntlig tysk, og får innsikt i kultur og samfunn i tysktalende land.',
    'Tysk I og II programfag utgjør én av to valgfrie fordypninger innenfor programområdet samfunnsfag, språk og økonomi. Faget passer for deg som har fullført fremmedspråk som obligatorisk fellesfag med et annet språk.',
    'Faget kan tas som poenggivende fag for søkere etter 23/5-regelen eller påbygg til generell studiekompetanse, og gir 0,5 språkpoeng.',
  ],
  'FSP6239/FSP6240': [
    'I Tysk I lærer du deg grunnleggende skriftlig og muntlig tysk, og får innsikt i kultur og samfunn i tysktalende land.',
    'Faget passer for deg som skal forbedre eller fullføre Tysk I fra videregående, eller har hatt nivå II i et annet språk og vil bytte. Har du hatt tysk på ungdomsskolen og vil fortsette med dette språket må du velge Tysk II fellesfag.',
    'Tysk I er ett av fagene du kan ta for å oppfylle kravene til 225 årstimer valgfritt fremmedspråk på videregående.',
  ],
  GEO1003: [
    'I Geografi lærer du hvordan jorden er bygget opp, hvordan klimaendringer vil påvirke oss og om bærekraftig utvikling.',
    'Hvordan er planeten vår bygget opp, hvorfor flytter folk som de gjør og hvordan analyserer du et kart? Geografi er et svært mangfoldig og aktuelt fag.',
    'Geografi er et obligatorisk fag på alle studieforberedende vitnemål.',
  ],
  'ENG1007/ENG1008': [
    'Engelsk må du ha hvis du skal ha generell studiekompetanse.',
    'Du lærer å beherske engelsk både skriftlig og muntlig, og blir kjent med forskjellige måter å snakke engelsk på rundt om i verden. Du får også god innsikt i samfunnet og kulturen i både Storbritannia og USA.',
  ],
  NOR1269: [
    'Norsk muntlig er for deg som trenger å forbedre muntligkarakteren din. Faget er ikke bestått med kun muntligeksamen, du må også ha norsk skriftlig i tillegg.',
    'Hvordan snakket vikingene, og hvordan har dette norrøne språket utviklet seg til nordiske dialekter? Hva skiller de nordiske språkene og hvordan har bokmål og nynorsk utviklet seg? Dette lærer du om i norskfaget.',
    'Dessuten lærer du om kommunikasjon og hvordan du kan tolke fagprosa og skjønnlitteratur. Du får oversikt over litteraturhistorie og hva som kjennetegner forfatterne og litteraturen i de ulike litterære periodene.',
  ],
  'NOR1267/NOR1268': [
    'Dette kurset er laget for deg som har tatt skriftlig eksamen i norsk hovedmål/sidemål tidligere, og som vil forbedre karakteren.',
    'Skrivekurset har to hovedtemaer: hvordan å skrive gode tekster, og hvordan å løse den type oppgaver som pleier å gis på norsk skriftlig eksamen.',
    'Du vil lære hvordan man skriver innledninger og strukturerer en artikkel i avsnitt, om siteringsteknikk og kildehenvisninger, og generelle råd om godt språk. Vi arbeider med konkrete eksamensoppgaver, slik som novelleanalyser, retoriske analyser, diktanalyser og lignende.',
    'Merk: Det skrivekurset ikke dekker, er det faglige innholdet som blir testet på eksamen, slik som litteratur-, kultur-, og språkhistorie. Siden kurset er laget for studenter som har gjennomført eksamen tidligere, forutsettes det at teorigrunnlaget er gjennomgått.',
  ],
  NOR1268: [
    'Sliter du med å huske forskjell på sterke og svake nynorske verb, substantivbøyning og hva det var med an-be-het-else-ordene, er dette et kurs for deg.',
    'Her får du trent deg på å mestre den nynorske grammatikken, nynorsk språkføring og nynorske ord. Etter kurset skal du være godt rustet til eksamen i norsk sidemål.',
    'Dette skrivekurset dekker ikke det faglige innholdet som blir testet på eksamen, slik som litteratur-, kultur-, og språkhistorie.',
  ],
  'REA3056/ REA3058': [
    'Skal du studere arkitektur eller ingeniørfag må du ha Matematikk R1 og R2.',
    'Matematikk R1 og R2 er teoretiske matematikkfag som gir deg grunnlaget du trenger i fysikk og kjemi.',
    'Du begynner med R1, hvor du lærer blant annet om logaritmer, sannsynlighetsregning, geometri, vektorer og funksjoner. Når du går videre til R2, lærer du blant annet integralregning, rekker og trigonometri.',
    'Matematikk R1 og R2 er programfag i studiespesialiserende programområde, og gir til sammen 1,5 realfagspoeng.',
  ],
  'intro-MAT-online-s': [
    'Trenger du S1 for videre studier, men mangler nødvendige forkunnskaper? Har du P- matte i bunn, eller kanskje det er lenge siden du tok matematikk? I dette forkurset forbereder vi deg til å ta matematikk S1.',
    'I forkurset finner du korte økter som går rett til kjernen av viktige begreper og metoder i matematikk. Dette gir deg et godt grunnlag for å jobbe med Matematikk S1. Her får du god anledning til å repetere grunnleggende matematikk og matematikk spesifikt rettet mot S1.',
    'Du finner korte videoer som forklarer lærestoffet, og oppgaver for å øve på det. I tillegg finner du en oppgavesamling i slutten av hvert emne. Kurset inneholder også en modul om bruk av digitale verktøy som GeoGebra / CAS samt introduksjon til programmering / Python.',
  ],
  'intro-MAT-online-r': [
    'Trenger du R1 for videre studier, men mangler nødvendige forkunnskaper? Har du P- matte i bunn, eller kanskje det er lenge siden du tok matematikk? I dette forkurset forbereder vi deg til å ta matematikk R1.',
    'I forkurset finner du korte økter som går rett til kjernen av viktige begreper og metoder i matematikk. Dette gir deg et godt grunnlag for å jobbe med Matematikk R1. Her får du god anledning til å repetere grunnleggende matematikk og matematikk spesifikt rettet mot R1.',
    'Du finner korte videoer som forklarer lærestoffet, og oppgaver for å øve på det. I tillegg finner du en oppgavesamling i slutten av hvert emne. Kurset inneholder også en modul om bruk av digitale verktøy som GeoGebra / CAS samt introduksjon til programmering / Python.',
  ],
  'intro-MAT-online-p-p-y': [
    'Uroer du deg for matematikken? Eller skal du forbedre karakteren din og vil ha best mulig utgangspunkt? Dette forkurset passer for deg som skal ta praktisk matematikk 1P-Y, 1P, 2P-Y eller 2P.',
    'Dette forkurset går rett til kjernen av viktige begreper og metoder i matematikk. Dette gir deg grunnlaget for å forstå og jobbe videre med de samme temaene i fagene. Vi har også et eget emne om digitale verktøy i matematikkfaget.',
    'Her får du frisket opp grunnleggende matematikk fra grunnskolen slik at du får et godt utgangspunkt for å ta fatt på praktisk matematikk. Kurset passer for alle, enten målet er å bestå eller om det er å få toppkarakter.',
  ],
  'intro-MAT-online-t': [
    'Uroer du deg for matematikken? Eller skal du forbedre karakteren din og vil ha best mulig utgangspunkt? Dette forkurset passer for deg som skal ta T-matematikk.',
    'Dette forkurset går rett til kjernen av viktige begreper og metoder i T-matematikk. Dette gir deg grunnlaget for å forstå og jobbe videre med de samme temaene i fagene. Vi har også et eget emne om digitale verktøy i matematikkfaget.',
    'Her får du frisket opp grunnleggende matematikk fra grunnskolen slik at du får et godt utgangspunkt for å ta fatt på teoretisk matematikk. Kurset passer for alle, enten målet er å bestå eller om det er å få toppkarakter.',
  ],
  'ulike y-koder': [
    'Matematikk 1PY er praktisk matte som du trenger for å kunne ta yrkesfag og fagbrev.',
    'I Matematikk 1P-Y lærer du praktisk matematikk du får bruk for i hverdagen og i ditt fremtidige yrke. 1P-Y er praktisk matematikk og et obligatorisk fellesfag på Vg1 yrkesfaglig retning. For å få generell studiekompetanse trenger du Matematikk 1P-Y og 2P-Y.',
    'Kurset passer for deg som vil forbedre karakter i 1P-Y, eller hvis du har gått et yrkesfaglig løp og mangler karakter i faget.',
    'Etter Fagfornyelsen har hvert yrkesfaglige utdanningsprogram tilpassede kompetansemål i matematikk 1PY.',
  ],
  'intro-MAT1019/MAT1023': [
    'Dette forkurset er for deg som ønsker å ta opp P-matematikk, men har innsett at du mangler litt basiskunnskaper.',
    'I dette forkurset øver vi inn igjen glemte regneferdigheter i emner som brøk, prosent og algebra. Slik at du kan stille godt forberedt til å gjennomføre kurs i P-matematikk.',
    'Kurset er 40 timer, og går de siste to ukene før skolestart både på høsten og på våren.',
  ],
  'intro-MAT1019/MAT1023/MAT1151/MAT1021': [
    'Er det lenge siden du har hatt matte? Dette innføringskurset i matematikk er for deg som skal ta eksamen i matematikk P, T eller P-Y i løpet av skoleåret.',
    'I kurset lærer du grunnleggende matematikk fra ungdomsskolen, og det passer godt for deg som mangler de grunnleggende mattekunnskapene, eller rett og slett trenger repetisjon fordi du ikke har hatt matte på lenge.',
    'Kurset er rett før skolestart og er 10 timer.',
  ],
  'intro-REA3046/REA3047': [
    'Dette innføringskurset i kjemi er for deg som skal ta eksamen i Kjemi 2 i løpet av skoleåret. I dette kurset får du repetert og jobbet med emner fra Kjemi 1, slik at du får et solid grunnlag for å beherske faget på VG3-nivå. Det passer godt for deg som trenger repetisjon, for eksempel fordi du ikke har hatt kjemi på lenge.',
    'Kurset er rett før skolestart. Ta kontakt med din campus for å få vite dato for oppstart.',
  ],
  'lab-REA3035': [
    'Fordi store deler av karakteren din i Biologi 1 er muntlig-praktisk, anbefaler vi at du deltar på labkurs.',
    'Labøvelser er en del av muntlig-praktisk eksamen i Biologi 1, og legger grunnlaget for en tredjedel av din karakter. Labkurset er ikke obligatorisk, men vi anbefaler at du tar det for å være best mulig forberedt.',
    'Ved stor pågang prioriteres studenter som følger undervisning i Biologi 1 ved skolen. Labkurs går en gang i løpet av semesteret.',
  ],
  'intro-big-REA3056/REA3060': [
    'Er det en stund siden du gikk på videregående og hadde matematikk? Eller har du tatt P-matematikk på videregående, og bestemt deg for å gå videre med matematikk? Da kan det godt være at du trenger et krasj-kurs i forkunnskaper. Grunnleggende VGS- matematikk gir en god innføring i de emnene du må kunne før du tar fatt på R- eller S-matematikk. Kurset er kort og intensivt.',
    'Dette er hovedområdene i kurset:',
    [
      'Funksjoner, ligninger, derivasjon',
      'Potenser og logaritmer',
      'Matematiske modeller og vekstfart',
      'Sannsynlighetsregning',
      'Geometri',
      'Trigonometri',
    ],
  ],
  'intro-small-REA3056/REA3060': [
    'I kurset lærer du det viktigste fra 1T, og det passer godt for deg som mangler forkunnskapene til R1/S1, eller rett og slett trenger repetisjon fordi du ikke har hatt matte på lenge. Har du hatt P-matte i VG1, vil du ha stort utbytte av dette kurset.',
    'Kurset er rett før skolestart.',
  ],
  'lab-REA3036/REA3037': [
    'Labøvelser er en del av muntlig-praktisk eksamen i Biologi 2, og legger grunnlaget for en tredjedel av din karakter. Labkurs er ikke obligatorisk, men er anbefalt for å være best mulig forberedt til eksamen.',
    'Ved stor pågang prioriteres studenter som følger undervisning i Biologi 2 ved Sonans.',
  ],
  'lab-REA3038': [
    'Labøvelser er en del av muntlig-praktisk eksamen i Fysikk 1, og legger grunnlaget for en tredjedel av din karakter.',
    'Labkurset er ikke obligatorisk, men vi anbefaler at du tar det for å være best mulig forberedt.',
    'Ved stor pågang prioriteres studenter som følger undervisning i Fysikk 1 ved Sonans.',
  ],
  'lab-REA3039/REA3040': [
    'Labøvelser er en del av muntlig-praktisk eksamen i Fysikk 2, og legger grunnlaget for en tredjedel av din karakter.',
    'Labkurset er ikke obligatorisk, men vi anbefaler at du ar det for å være best mulig forberedt til eksamen.',
    'Ved stor pågang prioriteres studenter som følger undervisning i Fysikk 2 ved Sonans.',
  ],
  'lab-NAT1007': [
    'Labøvelser er en del av muntlig-praktisk eksamen i Naturfag, og legger grunnlaget for en tredjedel av din karakter.',
    'Vi anbefaler at du deltar på labkurs og gjennomfører labøvelser før muntlig-praktisk eksamen. Labkurs er ikke obligatorisk, men vi anbefaler at du tar det for å være best mulig forberedt til eksamen.',
    'Ved stor pågang prioriteres studenter som følger undervisning i Naturfag ved Sonans.',
  ],
  'lab-REA3046/REA3047': [
    'Labøvelser er en del av muntlig-praktisk eksamen i Kjemi 2, og legger grunnlaget for en tredjedel av din karakter.',
    'Labkurset er ikke obligatorisk, men gir et godt grunnlag for å kunne lykkes på eksamen.',
    'Ved stor pågang prioriteres studenter som følger undervisning i Kjemi 2 ved Sonans.',
  ],
  'lab-REA3045': [
    'Labøvelser er en del av muntlig-praktisk eksamen i Kjemi 1, og legger grunnlaget for en tredjedel av din karakter.',
    'Labkurset er ikke obligatorisk, men gir et godt grunnlagt for å ta lykkes på eksamen.',
    'Ved stor pågang prioriteres studenter som følger undervisning i Kjemi 1 ved Sonans.',
  ],
  'MAT1019/MAT1023': [
    'Matematikk 1P+2P gir deg generell kompetanse i matematikk. Dette er kunnskap du trenger både i hverdagen og i videre studier.',
    'I dette kurset begynner du med Matematikk 1P. Så går du videre til Matematikk 2P.',
  ],
  'REA3056/REA3058': [
    'Skal du studere arkitektur eller ingeniørfag må du ha Matematikk R1 og R2.',
    'Matematikk R1 og R2 er teoretiske matematikkfag som gir deg grunnlaget du trenger i fysikk og kjemi.',
    'Du begynner med R1, hvor du lærer blant annet om logaritmer, sannsynlighetsregning, geometri, vektorer og funksjoner. Når du går videre til R2, lærer du blant annet integralregning, rekker og trigonometri.',
    'Matematikk R1 og R2 er programfag i studiespesialiserende programområde, og gir til sammen 1,5 realfagspoeng.',
  ],

  'pack-MAT-online-MAT1019/MAT1023': [
    'Matematikk 1P+2P gir deg generell kompetanse i matematikk. Dette er kunnskap du trenger både i hverdagen og i videre studier.',
    'I dette kurset begynner du med Matematikk 1P. Så går du videre til Matematikk 2P.',
    'Pakken inkluderer et forkurs til praktisk matematikk slik at du får repetert grunnleggende matematikk fra ungdomsskolen.',
  ],
  'pack-MAT-online-ulike y-koder/MAT1151': [
    'Matematikk 1P-Y+2P-Y er matematikk fra førsteåret på yrkesfag og fra påbyggsåret på Vg3.',
    'I dette kurset lærer du matematikk som er viktig både i hverdagen, yrkeslivet og eventuelt i videre studier. Matematikk 1P-Y + 2P-Y dekker matematikkravet til generell studiekompetanse.',
    'Forkunnskaper til 1P-Y er bestått grunnskole og forkunnskaper til 2P-Y er 1P-Y.',
    'Matematikk 1P-Y+2P-Y dekker matematikkravet til generell studiekompetanse.',
    'Pakken inkluderer et forkurs til praktisk matematikk slik at du får repetert grunnleggende matematikk fra ungdomsskolen.',
  ],
  'FSP6219/FSP6220': [
    'Du lærer deg spansk på et grunnleggende nivå både skriftlig og muntlig. I tillegg får du innsikt i samfunnet og kulturen i spansktalende land.',
    'Spansk I er ett av fagene du kan ta for å oppfylle kravet til 225 årstimer valgfritt fremmedspråk på videregående.',
    'Faget er for deg som skal forbedre eller fullføre Spansk I fra videregående, eller har hatt nivå II i et annet språk - for eksempel tysk - og vil bytte språk. Har du hatt spansk på ungdomsskolen og vil fortsette med dette språket, må du velge Spansk II fellesfag.',
  ],
  'FSP6242/FSP6243': [
    'Du lærer å beherske grunnleggende tysk skriftlig og muntlig, og får innsikt i historie, geografi, kultur og samfunn i tysktalende land.',
    'Tysk II er ett av fagene du kan ta for å oppfylle kravet til 225 årstimer valgfritt fremmedspråk på videregående. Faget er for deg som skal forbedre eller fullføre Tysk II fra videregående eller som har hatt tysk på ungdomsskolen og vil fortsette med språket på videregående nivå.',
  ],
  'PSP5840/PSP5841': [
    'Du lærer å beherske grunnleggende tysk skriftlig og muntlig, og får innsikt i historie, geografi, kultur og samfunn i tysktalende land.',
    'Sammen med Tysk I programfag utgjør faget én av to valgfrie fordypninger innenfor programområde for samfunnsfag, språk og økonomi. Tysk II har et omfang på 140 årstimer.',
    'Tysk II programfag er for deg som ha fullført Tysk I som obligatorisk fellesfag eller som programfag.',
    'Tysk II programfag kan også tas som poenggivende fag for søkere etter 23/5-regelen eller påbygg til generell studiekompetanse. Du får 0,5 språkpoeng.',
  ],
  HSF1006: [
    'Helsefremmende arbeid er ett av tre obligatoriske programfag for Vg1 helse og oppvekstfag.',
    'Kompetansemålene tar blant annet for seg:',
    'Anatomi, fysiologi, kost og ernæring',
    'Grunnleggende førstehjelp, hygiene og smittevern',
    'Fysisk og psykisk helse, folkehelse og ergonomi',
    'Læring, mestring og god helse.',
  ],
  HSF1007: [
    'Kommunikasjon og samhandling er ett av tre obligatoriske programfag for Vg1 helse og oppvekstfag.',
    'Kompetansemålene tar blant annet for seg:',
    'Omsorg, service, kommunikasjon og digital dømmekraft',
    'Sosial og kulturell kompetanse og håndtering av konflikter',
    'Relasjonsferdigheter og observasjon',
  ],
  HSF1008: [
    'Yrkesliv i helse- og oppvekstfag er ett av tre obligatoriske programfag for Vg1 helse og oppvekstfag.',
    'Du lærer blant annet om:',
    'Profesjonell yrkesutøvelse',
    'Lover, regler og etiske dilemmaer',
    'Samfunnsendringer og teknologi',
    'Tverrfaglig samarbeid og organiseringen av helse- og oppvekstsektoren',
  ],
  HSF1009: [
    'For å bestå Vg1 for Helse og oppvekstfag må man i tillegg til de tre programfagene ha bestått en tverrfaglig eksamen hvor man ser fagene i sammenheng.',
    'I dette intensive eksamenskurset jobber vi med det tverrfaglige perspektivet og forbereder deg til muntlig-praktisk eksamen.',
    'Merk at det i noen tilfeller kreves at yrkesfaglig fordypning er godkjent før du kan melde deg opp til tverrfaglig eksamen.',
  ],
  HES2005: [
    'Helse og sykdom er ett av tre obligatoriske programfag for Vg2 Helseservicefag.',
    'Du lærer blant annet om:',
    'Sammenhenger mellom helse, livsstil, fysisk aktivitet og kosthold',
    'Fordøyelsessystemet og immunforsvaret og hva som påvirker det',
    'Smittsomme sykdommer, barnesykdommer og symptomer',
    'Akutte og kroniske sykdommer, svangerskap',
    'Legemidler og behandling',
  ],
  HES2006: [
    'Kommunikasjon og samhandling er ett av tre obligatoriske programfag for Vg2 Helseservicefag.',
    'Du lærer blant annet om:',
    'Kommunikasjon og samhandling med pasienter og pårørende',
    'Grunnleggende medisinske uttrykk',
    'Konflikt, stress, krisehåndtering og informasjonsarbeid',
    'Profesjonalitet, service og relasjonsferdigheter',
  ],
  HES2007: [
    'Yrkesliv i helseservicefag er ett av tre obligatoriske programfag for Vg2 Helseservicefag.',
    'Du lærer blant annet om:',
    'Administrasjon, kostander og dokumentasjon',
    'Smittevern og førstehjelp',
    'Digitale ressurser, offentlige elektroniske tjenester og regelverk',
    'Taushetsplikt og helse, miljø og sikkerhet',
    'Ergonomi og universell utforming',
    'Arbeidsforholdet, rettigheter og plikter',
  ],
  HES2008: [
    'For å bestå Vg2 for Helseservicefag må man i tillegg til de tre programfagene ha bestått en tverrfaglig eksamen hvor man ser fagene i sammenheng.',
    'I dette intensive eksamenskurset jobber vi med det tverrfaglige perspektivet og forbereder deg til muntlig-praktisk eksamen.',
    'Merk at det i noen tilfeller kreves at yrkesfaglig fordypning er godkjent før du kan melde deg opp til tverrfaglig eksamen.',
  ],
  HSE3005: [
    'Helse og sykdom er ett av tre obligatoriske programfag for Vg3 Helsesekretær.',
    'Du lærer blant annet om:',
    'Allmenntilstand, sykdom og smerte',
    'Livsstil og aldring',
    'Kroniske sykdommer og infeksjoner',
    'Plager og komplikasjoner i svangerskap',
    'Førstehjelp, smittevern og hygiene',
  ],
  HSE3006: [
    'Kommunikasjon og samhandling er ett av tre obligatoriske programfag for Vg3 Helsesekretær.',
    'Du lærer blant annet om:',
    'Service og kommunikasjon med pasienter og pårørende',
    'Observasjon, vurdering og dokumentasjon',
    'Veiledning av pasienter og pårørende',
    'Legemidler, rus, avhengighet og psykisk helse',
    'Stress- og konflikthåndtering',
    'Bruk av medisinsk fagterminologi',
    'Håndtering av stress og konflikter',
  ],
  HSE3007: [
    'Yrkesliv i helsesekretærfaget er ett av tre obligatoriske programfag for Vg3 Helsesekretær.',
    'Du lærer blant annet om:',
    'Planlegging, vurdering, dokumentasjon og administrasjon',
    'Assistanse helsearbeidere og laboratoriearbeid',
    'Regelverk for elektronisk pasientjournal',
    'Logistikk, bestilling og medisinsk utstyr',
    'Helse, miljø og sikkerhet, relevant lovverk og yrkesetiske retningslinjer',
  ],
  HSE3008: [
    'For å bestå Vg3 for Helsesekretær må man i tillegg til de tre programfagene ha bestått en tverrfaglig eksamen hvor man ser fagene i sammenheng.',
    'I dette intensive eksamenskurset jobber vi med det tverrfaglige perspektivet og forbereder deg til muntlig-praktisk eksamen.',
    'Merk at det i noen tilfeller kreves at yrkesfaglig fordypning er godkjent før du kan melde deg opp til tverrfaglig eksamen.',
  ],
  HEA2005: [
    'Helsefremmende arbeid er ett av tre obligatoriske programfag for Vg2 Helsearbeiderfag.',
    'Du lærer blant annet om:',
    'Kommunikasjon og samhandling med pasienter og pårørende',
    'Grunnleggende medisinske uttrykk',
    'Konflikt, stress, krisehåndtering og informasjonsarbeid',
    'Profesjonalitet, service og relasjonsferdigheter',
  ],
  HEA2006: [
    'Kommunikasjon og samhandling er ett av tre obligatoriske programfag for Vg2 Helsearbeiderfag.',
    'Du lærer blant annet om:',
    'Kommunikasjon og relasjonsferdigheter',
    'Tverrfaglig samarbeid og pasientforløp',
    'Respekt og toleranse',
    'Empati, holdninger og atferd',
    'Observasjon, rapportering og pasientsikkerhet',
    'Taushetsplikt, personvern og kommunikasjonsverktøy',
    'Konflikthåndtering og regelverk rundt makt og tvang',
  ],
  HEA2007: [
    'Yrkesliv i helsearbeiderfag er ett av tre obligatoriske programfag for Vg2 Helsearbeiderfag.',
    'Du lærer blant annet om:',
    'Profesjonalitet og pasient- og brukermedvirkning',
    'Regelverk i helse- og omsorgstjenesten og yrkesetikk',
    'Planlegging, gjennomføring, dokumentasjon og vurdering',
    'Hjelpemidler, velferdsteknologi, HMS og ergonomi',
    'Frivillige organisasjoner',
    'Rettigheter og plikter som helsefagarbeider',
  ],
  HEA2008: [
    'For å bestå Vg2 for Helsefagarbeider må man i tillegg til de tre programfagene ha bestått en tverrfaglig eksamen hvor man ser fagene i en tverrfaglig sammenheng.',
    'I dette intensive eksamenskurset jobber vi med det tverrfaglige perspektivet og forbereder deg til muntlig-praktisk eksamen.',
    'Merk at det i noen tilfeller kreves at yrkesfaglig fordypning er godkjent før du kan melde deg opp til tverrfaglig eksamen.',
  ],
  BUA2005: [
    'Pedagogisk arbeid er ett av tre obligatoriske programfag for Vg2 Barne- og ungdomsarbeiderfag.',
    'Du lærer blant annet om:',
    'Lekens betydning for barn og unges læring og utvikling',
    'Sosialiseringsprosesser hos barn og unge',
    'Aktiviteter og observasjonsmetoder i pedagogisk arbeid',
    'Utviklingspsykologi',
    'Veiledning av barn og unge',
    'Forebygging av kriminalitet og bruk av rusmidler',
  ],
  BUA2006: [
    'Kommunikasjon og samhandling er ett av tre obligatoriske programfag for Vg2 Barne- og ungdomsarbeiderfag.',
    'Du lærer blant annet om:',
    'Relasjoner og samspill mellom voksen og barn og unge',
    'Språkutvikling og personlighetsutvikling',
    'Kommunikasjonsmetoder, samtaler og tegn til tale',
    'Selvbilde og identitet',
    'Sosial- og kulturkompetanse',
    'konflikter og konflikthåndtering',
  ],
  BUA2007: [
    'Yrkesliv i barne- og ungdomsarbeiderfag er ett av tre obligatoriske programfag for Vg2 Barne- og ungdomsarbeiderfag.',
    'Du lærer blant annet om:',
    'Profesjonalitet og rollemodell',
    'Digital dømmekraft og lover og regler',
    'Brukermedvirkning og medbestemmelse i møte med barn og unge',
    'Mobbing, krenkende atferd og forebyggende tiltak',
    'Identitet, seksualitet og selvfølelse',
    'Alderstilpasset omsorg',
    'Hygiene, smitteverntiltak, HMS og ergonomi',
    'Grunnleggende førstehjelp',
    'Rettighetene og plikter som barne- og ungdomsarbeider',
  ],
  BUA2008: [
    'For å bestå Vg2 for Barne- og ungdomsarbeider må man i tillegg til de tre programfagene ha bestått en tverrfaglig eksamen hvor man ser fagene i en tverrfaglig sammenheng.',
    'I dette intensive eksamenskurset jobber vi med det tverrfaglige perspektivet og forbereder deg til muntlig-praktisk eksamen.',
    'Merk at det i noen tilfeller kreves at yrkesfaglig fordypning er godkjent før du kan melde deg opp til tverrfaglig eksamen.',
  ],
  HEA3103: [
    'Mangler du Vg1 og Vg2, men har arbeidserfaring innen helsefag? Da kan du med en skriftlig eksamen i dette faget kvalifisere deg for å ta fagbrev som praksiskandidat. Dette kurset forbereder deg til skriftlig eksamen i helsearbeiderfaget. Kompetansemålene er tilsvarende læreplan i Vg2 helsefagarbeider samt fordypning i henhold til kompetansemål for Vg3 – opplæring i bedrift.',
    'Merk at det er opplæringskontoret i ditt fylke som godkjenner praksisen din og slik gir deg godkjenning som praksiskandidat. Du kan få godkjent tidligere skole som en del av praksisen din på minimum 5 år.',
  ],
  BUA3103: [
    'Mangler du Vg1 og Vg2, men har arbeidserfaring innen barne- og ungdomsarbeid? Da kan du med en skriftlig eksamen i dette faget kvalifisere deg for å ta fagbrev som praksiskandidat. Dette kurset forbereder deg til skriftlig eksamen i barne- og ungdomsarbeiderfaget. Kompetansemålene er tilsvarende læreplan i Vg2 barne- og ungdomsarbeiderfag samt fordypning i henhold til kompetansemål for Vg3 – opplæring i bedrift.',
    'Merk at det er opplæringskontoret i ditt fylke som godkjenner praksisen din og slik gir deg godkjenning som praksiskandidat. Du kan få godkjent tidligere skole som en del av praksisen din på minimum 5 år.',
  ],
  'intro-MAT-campus-p': [
    'Dette forkurset er for deg som ønsker å ta opp P-matematikk, men har innsett at du mangler litt basiskunnskaper.',
    'I dette forkurset øver vi inn igjen glemte regneferdigheter i emner som brøk, prosent og algebra. Slik at du kan stille godt forberedt til å gjennomføre kurs i P-matematikk.',
    'Kurset er 18 timer, og går de siste to ukene før skolestart på høsten.',
  ],
  'intro-MAT-campus-t-s-r': [
    'Er det en stund siden du gikk på videregående og hadde matematikk? Eller har du tatt P-matematikk på videregående, og bestemt deg for å gå videre med matematikk?',
    'Da kan det godt være at du trenger et krasj-kurs i forkunnskaper. Forkurset gir en god innføring i de emnene du må kunne før du tar fatt på R- eller S-matematikk. Kurset er kort og intensivt.',
    'Dette er hovedområdene i kurset:',
    'Algebra',
    'Funksjoner',
    'Grunnleggende geometri og trigonometri',
    'Forkurset gir ikke formell kompetanse, men er et nødvendig grunnlag for å oppnå gode resultater i S- og R-matematikken.',
    'Kurset er 18 timer, og går de siste to ukene før skolestart på høsten.',
  ],
  'NOR1420/NOR1421': [
    'Dette kurset er for deg som har kort botid i Norge, og mangler norskfaget for å få norsk studiekompetanse. Du må kunne dokumentere at du har bodd i Norge kortere enn 6 år når du skal ta eksamen.',
    'Hvordan virker reklame? Hvorfor har vi to skriftspråk? Hvorfor snakker folk på Frogner annerledes enn de på Vålerenga? Og hvordan skriver man egentlig en god artikkel? Alt dette og mer lærer du om i norskfaget.',
    'Læreplanen i norsk for elever med kort botid er likeverdig ordinær læreplan i norsk, men har mer vekt på språklæring. Det betyr at vi kommer til å jobbe mye med skriving, språklig flyt, grammatikk og uttrykk. Kravene i skriftlig er lavere på enkelte områder enn i det ordinære norskfaget, mens for muntlig norsk gjelder de samme kravene i begge fag.',
    'Kurset passer for motiverte, hardt arbeidende studenter - det er et fagkurs, og ikke et språkkurs. Kurset dekker både litteratur- og språkhistorie. I tillegg er det kulturhistorie og krav til tekstlesing.',
    'Vi anbefaler at du snakker med en rådgiver før du bestiller kurset for å sikre at dette er riktig kurs for deg.',
    'Du kan ta Norsk vg3 kort botid online fra og med våren 2025.',
    'I Norsk vg3 kort botid må du opp i 2 eksamener: hovedmål skriftlig og norsk muntlig.',
  ],
};

export default GetCourseInfo;
