import { useEffect, useState } from 'react';
import { Header, Container, NavigationPearls } from './features/UI';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Course, Place, Verification } from './features/Pages';
import './App.css';
import { GetStructuredLocations } from './features/Helpers/StructureData/StructureData';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPacks } from './features/Reducers/packs';
import { AppDispatch, AppState } from './types';
import { GetAvailableSemesters } from './features/Helpers/Functions/GetAvailableSemesters';
import { setSemester } from './features/Helpers/Functions/SetSemester';
import { Form } from './features/Pages/Form';
import { ConfirmPage } from './features/Pages/Confirm';


function App() {
  const [selectedPlace, setSelectedPlace] = useState<boolean>(false);
  const location = useLocation();

  const pearlLinks = [
    { title: 'Sted', url: '/' },
    { title: 'Fag og priser', url: '/fag' },
    { title: 'Påmelding', url: '/paamelding' },
  ];

  useEffect(() => {
    const semesters = GetAvailableSemesters();
    setSemester(semesters[0]);
    getPacks();
    let completedSale = localStorage.getItem('completed_sale') || '';
    localStorage.removeItem('prevSem');

    if (completedSale === 'true') {
      localStorage.removeItem('completed_sale');
      localStorage.setItem('selectedCourses', '');
      localStorage.setItem('selectedPack', '');
    }
  }, []);

  function setNextArrow() {
    setSelectedPlace(true);
  }

  useEffect(() => {
    if (location.pathname !== '/') {
      setNextArrow();
    }
  }, [location]);

  const urlArr = ['/', '/fag', '/paamelding', '/bestill'];

  const dispatch = useDispatch<AppDispatch>();

  function getPacks() {
    dispatch(fetchPacks());
  }

  const { structuredPacks } = useSelector((state: AppState) => state.packs);

  const places = GetStructuredLocations();

  return (
    <div className='App'>
      <Header />
      <Container className='py-4 tablet-s:py-2 laptop-m:py-8'>
        <NavigationPearls links={pearlLinks} />
        <Routes>
          <Route path='/' element={<Place places={places} setNextArrow={() => setNextArrow()} />} />
          <Route path='/fag' element={<Course packs={structuredPacks} />} />
          <Route path='/sted' element={<Place places={places} setNextArrow={() => setNextArrow()} />} />
          <Route path='/paamelding' element={<Verification />} />
          <Route path='/bestill' element={<Form />} />
          <Route path='/bekreftet' element={<ConfirmPage />} />
        </Routes>
      </Container>
    </div>
  );
}

export default App;
